import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
import { environment } from '../../environments/environment';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { MainBreadcrumbService } from '../main/main-breadcrumb/main-breadcrumb.service';
import { AddFButtonComponent } from '../main/dynamic/block0/add-f-button/add-f-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { MainMenuService } from '../main-menu.service';
import { InfiniteScrollModule } from "ngx-infinite-scroll"
import { AppRoutingModule } from '../app-routing.module';
// import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
// import { NgxSortableModule } from 'ngx-sortable'
// import { AngularCesiumModule } from 'angular-cesium';
import { Draggable } from 'ng2draggable/draggable.directive';
//import { SanitizeHtmlPipe } from './services/pipes/SanitizeHtmlPipe';
import { MainComponent } from '../main/main.component';
import { MainHeaderComponent } from '../main/main-header/main-header.component';
import { MainSidenavComponent } from '../main/main-sidenav/main-sidenav.component';
import { MainPropertyComponent } from '../main/main-property/main-property.component';
import { MainBreadcrumbComponent } from '../main/main-breadcrumb/main-breadcrumb.component';
import { MobileFooterComponent } from '../main/mobile-footer/mobile-footer.component';
import { MobileCardComponent } from '../main/mobile-card/mobile-card.component';
import { DynamicComponent } from '../main/dynamic/dynamic.component';
import { Block1Component, ConfirmDialog } from '../main/dynamic/block1/block1.component';
import { Block2Component } from '../main/dynamic/block2/block2.component';
import { Block3Component,ConfirmExit,AddNewMaster } from '../main/dynamic/block3/block3.component';
import { Block0Component} from '../main/dynamic/block0/block0.component';
import { Filter} from '../main/dynamic/block0/filter.component';
import { PhotoBlockComponent, DescriptionComponent, ConfirmDelete } from '../main/dynamic/photo-block/photo-block.component';
import { MapComponent, CreateAddressComponent, ExistingPropertyComponent, PolygonSelection, BottomSheet } from '../main/map/map.component';
import { ParcelComponent,UpdateParcelComponent } from '../main/parcel/parcel.component';
import { FloorComponent, AddFloorComponent, FloorMapWebComponent } from '../main/dynamic/floor/floor.component';
import { UnitComponent, AddUnitComponent, FloorPasteUnitComponent, ConfirmRemove } from '../main/dynamic/unit/unit.component';
import { FloorGroupComponent, FloorPasteComponent } from '../main/dynamic/floor-group/floor-group.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes} from 'ngx-loading';
import { LandingPageComponent } from '../main/landing-page/landing-page.component';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { FloorSubgroupComponent } from '../main/dynamic/floor-subgroup/floor-subgroup.component';
import { UnitGroupComponent } from '../main/dynamic/unit-group/unit-group.component';
import { UnitSubgroupComponent } from '../main/dynamic/unit-subgroup/unit-subgroup.component';
import { CountryComponent } from '../main/country/country.component';
import { AuthGuard } from '../services/auth.guard';
import { FloorMapComponent } from '../main/floor-map/floor-map.component';
import { EditUnitComponent, MasterMultiselectFloorMap } from '../main/floor-map/edit-unit/edit-unit.component';
// import {PopoverModule} from "ngx-popover";
import { QuickEditComponent } from '../main/quick-edit/quick-edit.component';
// import { MdePopoverModule } from '@material-extended/mde';
// import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { MobileToolbarComponent } from '../mobile-toolbar/mobile-toolbar.component';
// import { MessagingService } from '../services/messaging.service';
import { MainRoutingModule } from './main-routing/main-routing.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { SubmitPropertyDialogComponent } from './submit-property-dialog/submit-property-dialog.component';
import { InterceptorService } from './interceptor.service';
import { ImageEditorComponent } from './profile/image-editor/image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileComponent } from './profile.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-2';
import { PipesModule } from './pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MasterMultiselect } from './dynamic/block3/master-multiselect';
import { QuickEditMultiselectComponent } from './quick-edit/quick-edit-multiselect/quick-edit-multiselect.component';
import { QuickConfirmExit } from './quick-edit/quick-confirm-exit/quick-confirm-exit.component';
import { ToolboxDialogComponent } from './floor-map/components/tool-box-dialog/tool-box-dialog.component';
import { UnitGroupList } from './floor-map/components/unit-list-group/unit-list-group.component';
import { ToolboxComponent } from './floor-map/components/tool-box/tool-box.component';

@NgModule({
    declarations: [
        Draggable,
        MainComponent,
        MainHeaderComponent,
        MainSidenavComponent,
        MainPropertyComponent,
        MainBreadcrumbComponent,
        MobileFooterComponent,
        MobileCardComponent,
        DynamicComponent,
        Block1Component,
        ConfirmDialog,
        Block2Component,
        Block3Component,
        ConfirmExit,
        AddNewMaster,
        QuickConfirmExit,
        Block0Component,
        Filter,
        PhotoBlockComponent,
        CreateAddressComponent,
        DescriptionComponent,
        MapComponent,
        PolygonSelection,
        FloorComponent,
        FloorMapWebComponent,
        UnitComponent,
        ConfirmRemove,
        FloorGroupComponent,
        FloorPasteComponent,
        AddFloorComponent,
        AddUnitComponent,
        ExistingPropertyComponent,
        ProfileComponent,
        FloorPasteUnitComponent,
        LandingPageComponent,
        ConfirmDelete,
        FloorSubgroupComponent,
        AddFButtonComponent,
        UnitGroupComponent,
        UnitSubgroupComponent,
        CountryComponent,
        FloorMapComponent,
        ToolboxComponent,
        ToolboxDialogComponent,
        UnitGroupList,
        EditUnitComponent,
        QuickEditComponent,
        MasterMultiselectFloorMap,
        // MasterMultiselectQuick,
        MobileToolbarComponent,
        ParcelComponent,
        UpdateParcelComponent,
        MasterMultiselect,
        SubmitPropertyDialogComponent,
        BottomSheet,
        ImageEditorComponent,
        QuickEditMultiselectComponent
        //SanitizeHtmlPipe,
    ],
    imports: [
        BrowserModule,
        InfiniteScrollModule,
        AppRoutingModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatTabsModule,
        MatGridListModule,
        MatMenuModule,
        // AngularCesiumModule.forRoot(),
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatTableModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatRadioModule,
        MatSnackBarModule,
        MatProgressBarModule,
        HttpClientModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        // PopoverModule,
        BrowserAnimationsModule,
        FormsModule, ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaV3Module,
        RecaptchaFormsModule,
        ServiceWorkerModule,
        DragDropModule,
        MatFormFieldModule,
        MatInputModule,
         MatDatepickerModule,
          MatNativeDateModule,
        // NgxSortableModule,
        NgxIntlTelInputModule,
        // DeviceDetectorModule.forRoot(),
        environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
        // SortablejsModule,
        // MdePopoverModule,
        // MultiselectDropdownModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '4px',
            primaryColour: '#f69230',
            secondaryColour: '#16ceed',
            tertiaryColour: '#23cb6b',
            fullScreenBackdrop: false
        }),
        MainRoutingModule,
        ImageCropperModule,
        PipesModule
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }
    ],
    bootstrap: [MainComponent]
})
export class MainModule { }
