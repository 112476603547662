/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_masters_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
 * <li>estapi_entity_masters_master_id_values_get</li>
 * <li>gisapi_units_post</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_unit_post</li>
 * </ol>
 *
 */

import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../../../src/app/services/apiservice.service";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { ShareService } from "../../../../../../src/app/services/share.service";

@Component({
  selector: "toolbox-map-dialog",
  templateUrl: "./toolbox-dialog.component.html",
  styleUrls: ["../../floor-map.component.css"],
})
export class ToolboxDialogComponent {
  propertyTypes: any;
  createUnitForm: UntypedFormGroup;
  unit_type_list: any = [];
  master_id: number;
  valObj: any;
  loader: boolean = false;
  assignment_detail: any;
  constructor(
    public dialogRef: MatDialogRef<ToolboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private share: ShareService,
    private api: ApiserviceService,
    private fb: UntypedFormBuilder,
    private fnc:CommonfunctionService
  ) {
    this.share.toolbox_dialog_obj = this;
    this.createUnitForm = new UntypedFormGroup({
      property_type: new UntypedFormControl("", [Validators.required]),
      unit_sub_category: new UntypedFormControl("", [Validators.required]),
      unit_area: new UntypedFormControl("", [Validators.required]),
      unit_areaunit: new UntypedFormControl("Sq M", [Validators.required]),
      unit_number: new UntypedFormControl("", [Validators.required]),
    });
    this.assignment_detail = JSON.parse(
      localStorage.getItem("assignment_detail")
    );
    this.propertyTypes = this.share.unit_obj.property_type_list;
  }
  getSubUnit(e) {
    this.unit_type_list = [];
    if (!this.master_id) {
      this.api
        .getEmsData("masters?master_name=unit_types&limit=1000")
        .subscribe((data: any) => {
          this.master_id = data[0].master_id;
          this.api
            .getEmsData(
              "property_unit/masters/" +
                this.master_id +
                "/values?status=1&limit=1000&property_type_ids=" +
                e.value
            )
            .subscribe((val: any) => {
              let result = {};
              val.forEach((v) => {
                result = {
                  id: v.master_value_id,
                  label: v.master_value_label[this.api.language],
                  name: v.master_value_name,
                  master_id: v.master_id,
                  status: v.status,
                  property_type_id: v.property_type_id,
                };
                this.unit_type_list.push(result);
              });
            });
        });
    } else {
      this.api
        .getEmsData(
          "property_unit/masters/" +
            this.master_id +
            "/values?status=1&limit=1000&property_type_ids=" +
            e.value
        )
        .subscribe((val: any) => {
          let result = {};
          val.forEach((v) => {
            result = {
              id: v.master_value_id,
              label: v.master_value_label[this.api.language],
              name: v.master_value_name,
              master_id: v.master_id,
              status: v.status,
              property_type_id: v.property_type_id,
            };
            this.unit_type_list.push(result);
          });
        });
    }
  }

  saveUnit(event) {
    let val = event.value;
    this.valObj = val;
    let category = this.fnc.getArrayValue(
      "id",
      val.property_type,
      this.propertyTypes
    );
    this.createGisUnit(null,category.name);

    // let url = "units/";
    // let body = {
    //   added_by: this.api.user_id,
    //   category: category.name,
    //   floor_uid: this.share.floor_map_obj.selected_floor.gid,
    //   geom: this.share.floor_map_obj.geomNew,
    //   sub_category: val.unit_sub_category,
    //   unit_area: val.unit_area,
    //   unit_height: 0,
    //   unit_number: val.unit_number,
    //   unit_url: null,
    // };
    // this.api.postGmsData(url, body).subscribe(
    //   (data: any) => {
    //     let gid = data;
    //     this.createGisUnit(gid.unit_uid, category.name);
    //     this.share.floor_map_obj.notify.notify(
    //       "Unit successfully created!",
    //       "success"
    //     );
    //     this.share.floor_map_obj.enableDrawing = false;
    //     if (this.share.floor_map_obj.userShape) {
    //       this.share.floor_map_obj.userShape.setMap(null);
    //     }
    //   },
    //   (err) => {
    //     this.share.floor_map_obj.enableDrawing = false;
    //     if (this.share.floor_map_obj.userShape) {
    //       this.share.floor_map_obj.userShape.setMap(null);
    //     }
    //   }
    // );
    // this.share.floor_map_obj.reposition = true;
    // this.share.toolbox_dialog_objdialogRef.close();
    this.share.toolbox_obj.setActive = false;
  }
  createGisUnit(gid=null, property_name) {
    let selected_unit = this.valObj;
    let body = {
      added_by: this.api.user_id,
      property_type_name: property_name,
      unit_type_name: selected_unit.unit_sub_category,
      unit_area: selected_unit.unit_area,
      unit_gis_uid: gid,
      unit_number: selected_unit.unit_number,
      status: 1,
    };
    this.loader = true;
    this.api
      .postEpsData(
        "deadmin/" +
          this.assignment_detail.assignment_name +
          "/properties/" +
          this.share.block0_obj.selected_property.id +
          "/floors/" +
          this.share.toolbox_obj.current_floor_info.id +
          "/unit",
        body
      )
      .subscribe({
        next: (data: any) => {
          if(data && data[0] && data[0].hasOwnProperty('est_add_property_unit')){
            // update geom newly create Unit
            let est_unit_id  = data[0].est_add_property_unit;
            let attr = this.share.block0_obj.attribute_list.find(e=> e.attribute_name == 'geom' &&  e.property_type_id == this.valObj.property_type);
            let geom_attr_id  = attr ? attr.attribute_id:'';
            if(!geom_attr_id){
              return;
            }
            let Geomdata = {
              "0": { [geom_attr_id]: this.share.floor_map_obj.geomNew },
              "1": {}
            };
            let update_body = {
              "data": Geomdata,
              "updated_by": this.api.user_id,
              "assignment_id":this.assignment_detail.assignment_id
            }
            this.api.patchEpsData(
              "deadmin/" +
                this.assignment_detail.assignment_name +
                "/properties/" +
                this.share.block0_obj.selected_property.id +
                "/floors/" +
                this.share.toolbox_obj.current_floor_info.id +
                "/units/"+est_unit_id,
              update_body
            ).subscribe({
              next:(res)=>{
              this.loader = false;
              console.log(res);
              this.share.toolbox_dialog_obj.loader = false;
              this.share.toolbox_dialog_obj.dialogRef.close();
              this.share.floor_map_obj.getUnitData();
              this.share.toolbox_obj.clear();
            }, error: ()=>{
              this.loader = false;
              this.share.toolbox_dialog_obj.loader = false;
              this.share.toolbox_dialog_obj.dialogRef.close();
            }})
          }else{
            this.loader = false;
            this.share.toolbox_dialog_obj.loader = false;
            this.share.toolbox_dialog_obj.dialogRef.close();
            this.share.floor_map_obj.getUnitData();
            this.share.toolbox_obj.clear();
          }
        },
        error:(err) => {
          this.loader = false;
            this.share.toolbox_dialog_obj.loader = false;
            this.share.toolbox_dialog_obj.dialogRef.close();

        }
      });
    this.closeDialog();
  }

  closeDialog() {
    // this.share.floor_map_obj.reposition = true;
    this.share.toolbox_obj.setActive = "";
    this.dialogRef.close();
    this.share.toolbox_obj.clear();
    this.share.toolbox_obj.setActive = false;
  }
}
