
<!-- <app-quick-edit-form></app-quick-edit-form> -->
<div>
  <div class="dialog">
    <div>
      <mat-toolbar class="tool">
        <div class="w-100 topbar" >
          <span *ngIf="image_upload" class="lnk" (click)="backFormSec()">
            <mat-icon id="back-arrow" svgIcon="back-arrow"></mat-icon>
          </span>
          <span>{{selected_obj}}</span>
          <span class="lnk" (click)="switchImage()" *ngIf="!image_upload">
            <mat-icon>add_a_photo</mat-icon>
          </span>
        </div>
      </mat-toolbar>
    </div>
    <!-- <popover-content #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true">
      <div class="rejectmessage">{{rejectmessage}}</div>
    </popover-content> -->

    <form id="property_form" [formGroup]="propertyForm" (ngSubmit)="createProperty(propertyForm)" *ngIf="!image_upload">
      <!-- form web template start -->
     <div class="form-field-wrapper">
      <ng-template #formTemplate let-attrib="attrib" let-parent="parent">
        <input type="radio" id="radio_{{attrib.id}}_1" value="on" name="radio_{{attrib.id}}"
          *ngIf="attrib.mutual.length > 0" (change)="showChild(attrib,$event,1)" />

        <span class="df" id="span_{{attrib.id}}">{{attrib.label}}
          <!-- information tooltip start -->
          <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
            class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
          <!-- information tooltip end -->
        </span>

        <input class="form-control input-sm " [ngClass]="{'measure_unit_control': isIdMatched(attrib.id)}" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          type="number"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
          *ngIf="attrib.data_type == 'decimal-input' || attrib.data_type == 'number-input'">

        <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" type="text"
          *ngIf="attrib.data_type == 'string-input'">
        <!-- <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" type="date" [min]="min_date"  [max]="max_date"
          *ngIf="attrib.data_type == 'date'"> -->
        <div class="ml-10 d-flex align-items-center date-picker" style="width: 100%;"  *ngIf="attrib.data_type == 'date'">
          <span class="d-flex align-items-center position-relative" (click)="focusPicker(picker1)">
              <input matInput [min]="min_date" maxlength="10"   [max]="max_date" class="form-select-l" id="input_{{attrib.id}}"
              formControlName="input_{{attrib.id}}" style="padding :8px 8px !important;" [matDatepicker]="picker1"  readonly>
              <span class="position-absolute righ-0">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </span>
          </span>
      </div>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'year'">
          <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
        </mat-select>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'month'">
          <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
        </mat-select>
        <ng-container *ngIf="!parent">


          <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib, true)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}" ></output>
          </div>


          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
             class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}" ></output>
          </div>

        </ng-container>

        <ng-container *ngIf="parent">
          <mat-select (click)="getMasterValues(attrib.master_id)" (selectionChange)="changeChild($event.value,attrib)"
            class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'single-select'">
            <div class="pad5 rel custom">
              <div class="fixed-in">
                <input formControlName="search_{{attrib.id}}" class="form-control input-sm minw fixed-in" type="text"
                  placeholder="Search" (keyup)="find($event,attrib)" (keydown)="$event.stopPropagation()" />
              </div>
              <mat-option *ngIf="newValue(attrib, searchTerm)" (click)="addNewMasterValue(searchTerm,attrib,parent)"
                value="addNewOption">Add "{{searchTerm}}"</mat-option>
              <mat-option *ngFor="let master of getList(attrib,searchTerm,parent)" value="{{master.id}}">
                {{master.label}}</mat-option>
            </div>
            <!-- <mat-option *ngFor="let master of getChildMasterValue(attrib,parent)" value="{{master.id}}">{{master.label}}</mat-option> -->
          </mat-select>


          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
             class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}"></output>
          </div>

        </ng-container>

        <ng-container *ngIf="attrib.data_type == 'toggle'">
          <div class="toggle_wrappr">
            <label [ngClass]="{'disabled-div':!attrib.is_editable}" class="switch" id="div_{{attrib.id}}_1">
              <input type="checkbox" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" class="chkBox"
                (change)="checkChild(attrib,$event,'checkbox')">
              <span class="slider round">
                <div class="toggle-container">
                  <span class="text">Yes</span>
                  <span class="text">No</span>
                </div>
              </span>
            </label>
          </div>
        </ng-container>
        <mat-select class="form-select-md unit-drop-down" id="unit_{{attrib.id}}" formControlName="unit_{{attrib.id}}"
          *ngIf="attrib.measure_unit">
          <mat-option *ngFor="let unit of getMeasureUnit(attrib.measure_type)" value="{{unit}}">{{unit}}</mat-option>
        </mat-select>
      </ng-template>

      <ng-template #childElement let-dep="dep" , let-parent="parent">
        <div [ngClass]="[dep.mutual.length > 0 ? 'field-dep-mutual' : 'field-dep', dep?.data_type == 'toggle' ? 'toggle_wrappr' : '']">
          <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:parent}"></ng-container>
        </div>
        <!-- mutual under child start -->
        <div *ngIf="dep.mutual.length > 0" class="mutual-child border">
          <input type="radio" id="radio_{{dep.id}}_2" name="radio_{{dep.id}}" (change)="showChild(dep,$event,2)" />
          <div>
            <div id="div_{{dep.id}}_2" class="disabled-div">
              <div *ngFor="let mt of dep.mutual" class="field-dep">
                <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:mt,parent:false}"></ng-container>
              </div>
            </div>
          </div>
        </div>

      </ng-template>


      <div *ngFor="let attrib of customize_attribute">
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type != 'table'">
          <div class="form-group box1">
            <div [ngClass]="[attrib.mutual.length ? 'field-mutual' : 'field']">
              <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:attrib,parent:false}"></ng-container>
            </div>
            <!-- Child attributes start -->
            <div *ngIf="attrib.child.length >= 1" id="child_{{attrib.id}}" class="child_wrapper">
              <div *ngFor="let dep of attrib.child">
                <ng-container *ngTemplateOutlet="childElement;context:{dep:dep,parent:attrib}"></ng-container>
              </div>
            </div>
            <!-- Child attributes end -->

            <!-- Mutual attributes start -->
            <div *ngIf="attrib.mutual.length >= 1" class="mutual-div">
              <input type="radio" value="off" id="radio_{{attrib.id}}_2" name="radio_{{attrib.id}}"
                (change)="showChild(attrib,$event,2)" />
              <div id="div_{{attrib.id}}_2" class="disabled-div">
                <div *ngFor="let dep of attrib.mutual" class="field">
                  <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:false}"></ng-container>
                </div>
              </div>
            </div>
            <!-- Mutual attributes end -->
          </div>

          <!-- attributtes without child and dependent attribute end  -->

        </ng-container>
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type == 'table'">
          <div class="form-group box1">
            <div>
              <label class="label-text">{{attrib.label}}</label>
              <!-- information tooltip start -->
              <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
                class="error"><i class="material-icons">error</i></span>
              <!-- information tooltip end -->
            </div>
            <div class="master-container" *ngFor="let masterval of getMasterById(attrib.master_id)">
              <div class="master-field" *ngIf="masterval">
                <label [ngClass]="{'child_attrib_label': attrib.child.length}">{{masterval.label}}</label>
                <ng-container *ngFor="let child of attrib.child">
                  <ng-container *ngIf="child.status && !child.is_hidden">
                    <input class="form-control input-sm" type="number" [ngClass]="{'child_attrib': attrib.child.length}"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                      id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}"
                      *ngIf="child.data_type == 'decimal-input' || child.data_type == 'number-input'">
                    <input class="form-control input-sm" type="text" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'string-input'">
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'single-select'">
                      <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" value="{{master.id}}">
                        {{master.label}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" multiple
                      *ngIf="child.data_type == 'multi-select'">
                      <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" value="{{master.id}}">
                        {{master.label}}</mat-option>
                    </mat-select>
                    <label class="switch" *ngIf="child.data_type == 'toggle'">
                      <input type="checkbox" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" class="chkBox" checked>
                      <span class="slider round">
                        <div class="toggle-container">
                          <span class="text">Yes</span>
                          <span class="text">No</span>
                        </div>
                      </span>
                    </label>
                    <!-- <input class="form-control input-sm" type="date" [min]="min_date"  [max]="max_date" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'date'"> -->
                      <div class="ml-10 d-flex align-items-center"  *ngIf="child.data_type == 'date'">
                        <span class="d-flex align-items-center mx-2 position-relative" (click)="focusPicker(picker1)">
                            <input matInput [min]="min_date" maxlength="10"  [max]="max_date" class="form-select-l" id="input_{{masterval.id}}_{{child.id}}"
                            formControlName="input_{{masterval.id}}_{{child.id}}" style="padding :8px 8px !important;" [matDatepicker]="picker1"  readonly>
                            <span class="position-absolute righ-0">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                          </span>
                        </span>
                    </div>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'year'">
                      <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'month'">
                      <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="unit_{{masterval.id}}_{{child.id}}"
                      formControlName="unit_{{masterval.id}}_{{child.id}}" *ngIf="child.measure_unit">
                      <mat-option *ngFor="let unit of getMeasureUnit(child.measure_type)" value="{{unit}}">{{unit}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                </ng-container>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
      </div>
      <!-- using hidden button to submit the form hidden button  -->
      <!-- <input style="display: none" id="property_form_btn" type="submit" name="submit" /> -->
      <div class="action">
        <div>
          <button type="submit" mat-stroked-button color name="submit" class="b-btn" id="property_form_btn">
            <span>Update</span>
            <!-- <span *ngIf="loader">Saving...</span> -->
          </button>
        </div>
        <div *ngIf="mobile">
          <button type="button" mat-stroked-button color (click)="gotogroup()">Go to Group</button>
        </div>
        <div>
          <button type="button" mat-stroked-button color (click)="closeDialog()">Cancel</button>
        </div>
      </div>
    </form>
    <div *ngIf="image_upload">
      <app-photo-block></app-photo-block>
    </div>

  </div>
</div>
<div *ngIf="false">
  <div class="dialog">
    <div>
      <mat-toolbar class="tool grid-ap">
        <span>{{selected_obj}}</span>
        <!-- <mat-icon (click)="closeDialog()" class="danger">close</mat-icon> -->
        <mat-icon (click)="switchImage()">add_a_photo</mat-icon>
      </mat-toolbar>

    </div>

    <!-- <div #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true">
      <div class="rejectmessage">{{rejectmessage}}</div>
    </div>  -->

    <form *ngIf="!image_upload" id="property_form" [formGroup]="propertyForm" (ngSubmit)="createProperty(propertyForm)">
      <!-- form web template start -->
      <ng-template #formTemplate let-attrib="attrib" let-parent="parent">
        <input type="radio" id="radio_{{attrib.id}}_1" value="on" name="radio_{{attrib.id}}"
          *ngIf="attrib.mutual.length > 0" (change)="showChild(attrib,$event,1)" />

        <span class="df" id="span_{{attrib.id}}">{{attrib.label}}
          <!-- information tooltip start -->
          <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
            class="error" matTooltip={{rejectmessage}}><i class="material-icons">error</i> </span>
          <!-- information tooltip end -->

        </span>

        <input class="form-control input-sm width-50" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          type="number"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
          *ngIf="attrib.data_type == 'decimal-input' || attrib.data_type == 'number-input'">

        <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" type="text"
          *ngIf="attrib.data_type == 'string-input'">
        <!-- <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" type="date" [min]="min_date"  [max]="max_date"
          *ngIf="attrib.data_type == 'date'"> -->
          <div class="ml-10 d-flex align-items-center"  *ngIf="child.data_type == 'date'">
            <span class="d-flex align-items-center mx-2 position-relative" (click)="focusPicker(picker1)">
                <input matInput [min]="min_date" maxlength="10"  [max]="max_date" class="form-select-l"
                 id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
                 style="padding :8px 8px !important;" [matDatepicker]="picker1"  readonly>
                <span class="position-absolute righ-0">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
              </span>
            </span>
        </div>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'year'">
          <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
        </mat-select>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'month'">
          <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
        </mat-select>
        <ng-container *ngIf="!parent">


          <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib, true)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}"></output>
          </div>


          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
             class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}"></output>
          </div>

        </ng-container>

        <ng-container *ngIf="parent">
          <mat-select (click)="getMasterValues(attrib.master_id)" (selectionChange)="changeChild($event.value,attrib)"
            class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'single-select'">
            <div class="pad5 rel custom">
              <div class="fixed-in">
                <input formControlName="search_{{attrib.id}}" class="form-control input-sm minw fixed-in" type="text"
                  placeholder="Search" (keyup)="find($event,attrib)" (keydown)="$event.stopPropagation()" />
              </div>
              <mat-option *ngIf="newValue(attrib, searchTerm)" (click)="addNewMasterValue(searchTerm,attrib,parent)"
                value="addNewOption">Add "{{searchTerm}}"</mat-option>
              <mat-option *ngFor="let master of getList(attrib,searchTerm,parent)" value="{{master.id}}">
                {{master.label}}</mat-option>
            </div>
            <!-- <mat-option *ngFor="let master of getChildMasterValue(attrib,parent)" value="{{master.id}}">{{master.label}}</mat-option> -->
          </mat-select>


          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
             class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              [value]="getSelectedValues(attrib)">
            </mat-select>
            <output type="text" disabled="disabled" class="form-control input-sm minw lnk"
              id="input_custom_{{attrib.id}}"></output>
          </div>

        </ng-container>

        <ng-container *ngIf="attrib.data_type == 'toggle'">
     <div class="toggle_wrappr">
      <label [ngClass]="{'disabled-div':!attrib.is_editable}" class="switch" id="div_{{attrib.id}}_1">
        <input type="checkbox" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" class="chkBox"
          (change)="checkChild(attrib,$event,'checkbox')">
        <span class="slider round">
          <div class="toggle-container">
            <span class="text">Yes</span>
            <span class="text">No</span>
          </div>
        </span>
      </label>
     </div>
        </ng-container>
        <mat-select class="form-select-md" id="unit_{{attrib.id}}" formControlName="unit_{{attrib.id}}"
          *ngIf="attrib.measure_unit">
          <mat-option *ngFor="let unit of getMeasureUnit(attrib.measure_type)" value="{{unit}}">{{unit}}</mat-option>
        </mat-select>
      </ng-template>

      <ng-template #childElement let-dep="dep" , let-parent="parent">
        <div [ngClass]="[dep.mutual.length > 0 ? 'field-dep-mutual' : 'field-dep']">
          <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:parent}"></ng-container>
        </div>
        <!-- mutual under child start -->
        <div *ngIf="dep.mutual.length > 0" class="mutual-child border">
          <input type="radio" id="radio_{{dep.id}}_2" name="radio_{{dep.id}}" (change)="showChild(dep,$event,2)" />
          <div>
            <div id="div_{{dep.id}}_2" class="disabled-div">
              <div *ngFor="let mt of dep.mutual" class="field-dep">
                <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:mt,parent:false}"></ng-container>
              </div>
            </div>
          </div>
        </div>

      </ng-template>


      <div *ngFor="let attrib of customize_attribute">
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type != 'table'">
          <div class="form-group box1">
            <div [ngClass]="[attrib.mutual.length ? 'field-mutual' : 'field']">
              <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:attrib,parent:false}"></ng-container>
            </div>
            <!-- Child attributes start -->
            <div *ngIf="attrib.child.length >= 1" id="child_{{attrib.id}}">
              <div *ngFor="let dep of attrib.child">
                <ng-container *ngTemplateOutlet="childElement;context:{dep:dep,parent:attrib}"></ng-container>
              </div>
            </div>
            <!-- Child attributes end -->

            <!-- Mutual attributes start -->
            <div *ngIf="attrib.mutual.length >= 1" class="mutual-div">
              <input type="radio" value="off" id="radio_{{attrib.id}}_2" name="radio_{{attrib.id}}"
                (change)="showChild(attrib,$event,2)" />
              <div id="div_{{attrib.id}}_2" class="disabled-div">
                <div *ngFor="let dep of attrib.mutual" class="field">
                  <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:false}"></ng-container>
                </div>
              </div>
            </div>
            <!-- Mutual attributes end -->
          </div>

          <!-- attributtes without child and dependent attribute end  -->

        </ng-container>
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type == 'table'">
          <div class="form-group box1">
            <div>
              <label class="label-text">{{attrib.label}}</label>
              <!-- information tooltip start -->
              <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
                class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
              <!-- information tooltip end -->
            </div>
            <div class="master-container" *ngFor="let masterval of getMasterById(attrib.master_id)">
              <div class="master-field" *ngIf="masterval">
                <label>{{masterval.label}}</label>
                <ng-container *ngFor="let child of attrib.child">
                  <ng-container *ngIf="child.status && !child.is_hidden">
                    <input class="form-control input-sm width-50" type="number"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                      id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}"
                      *ngIf="child.data_type == 'decimal-input' || child.data_type == 'number-input'">
                    <input class="form-control input-sm" type="text" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'string-input'">
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'single-select'">
                      <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" value="{{master.id}}">
                        {{master.label}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" multiple
                      *ngIf="child.data_type == 'multi-select'">
                      <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" value="{{master.id}}">
                        {{master.label}}</mat-option>
                    </mat-select>
                    <label class="switch" *ngIf="child.data_type == 'toggle'">
                      <input type="checkbox" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" class="chkBox" checked>
                      <span class="slider round">
                        <div class="toggle-container">
                          <span class="text">Yes</span>
                          <span class="text">No</span>
                        </div>
                      </span>
                    </label>
                    <!-- <input class="form-control input-sm" type="date" [min]="min_date"  [max]="max_date" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'date'"> -->
                      <div class="ml-10 d-flex align-items-center"  *ngIf="child.data_type == 'date'">
                        <span class="d-flex align-items-center mx-2 position-relative" (click)="focusPicker(picker1)">
                            <input matInput [min]="min_date"  maxlength="10"  [max]="max_date" class="form-select-l"
                             id="input_{{attrib.id}}" formControlName="input_{{masterval.id}}_{{child.id}}"
                             style="padding :8px 8px !important;" [matDatepicker]="picker1"  readonly>
                            <span class="position-absolute righ-0">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                          </span>
                        </span>
                    </div>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'year'">
                      <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'month'">
                      <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="unit_{{masterval.id}}_{{child.id}}"
                      formControlName="unit_{{masterval.id}}_{{child.id}}" *ngIf="child.measure_unit">
                      <mat-option *ngFor="let unit of getMeasureUnit(child.measure_type)" value="{{unit}}">{{unit}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                </ng-container>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- using hidden button to submit the form hidden button  -->
      <!-- <input style="display: none" id="property_form_btn" type="submit" name="submit" /> -->
      <div class="action">
          <div>
            <button type="submit" [disabled]="!propertyForm.valid"  name="submit" id="property_form_btn" >
              <span>Update</span>
              <!-- <span *ngIf="loader">Saving...</span> -->
            </button>
          </div>
          <div>
            <button type="button" mat-stroked-button color (click)="gotogroup()">Go to Group</button>
          </div>
          <div>
            <button (click)="openDialog()" mat-stroked-button class="submit-btn" title="submit property"><i class="material-icons">
              cloud_upload
              </i></button>
          </div>
        </div>
    </form>
    <div *ngIf="image_upload">
        <app-photo-block></app-photo-block>
      </div>

  </div>
</div>
