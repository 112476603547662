import { _isNumberValue } from '@angular/cdk/coercion';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DatePipe, Location, PlatformLocation } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { debounceTime } from 'rxjs';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import { ShareService } from '../../services/share.service';
import { SubmitPropertyDialogComponent } from '../submit-property-dialog/submit-property-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { QuickEditMultiselectComponent } from './quick-edit-multiselect/quick-edit-multiselect.component';
import { QuickConfirmExit } from './quick-confirm-exit/quick-confirm-exit.component';

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface customizeAttributeList {
  id: number,
  name: string,
  label: string,
  data_type: string,
  measure_type: string,
  measure_unit: string,
  default_value: string,
  is_mandatory: boolean,
  is_hidden: boolean,
  is_editable: boolean,
  is_lang_dependent,
  status: boolean,
  master_id: number,
  has_linked_master : boolean,
  dependent: Array<any>,
  mutual: Array<any>,
  child: Array<any>
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_entity_masters_masters_id_values_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_patch</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
 * <li>estapi_entity_masters_masters_id_values_post</li>
 * <li>estapi_attribute_measure_unit_type_measureunits_get</li>
 * <li>estapi_attribute_measureunittypes_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-quick-edit',
  templateUrl: './quick-edit.component.html',
  styleUrls: ['./quick-edit.component.css'],
  providers: [DatePipe,
      { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },]
})
export class QuickEditComponent implements OnInit {
  //variables for search in mat select
  searchTerm: string;
  searchTerm1: string;
  newlist: any = [];
  // errormessage= false;
  selectedAttrId: any;
  addOtherOption:boolean = false;
  confirmation: boolean = false;
  loader1:boolean = false;
  //variables for search in mat select
  mobile: boolean = false;
  is_valid = false;
  dHeight: number;
  selected_property: any;
  selected_group: any;
  selected_sub_group: any;
  attribute_list: any = [];
  group_id: number;
  group_name: string;
  group_attribute: any = [];
  used_attribute: any = [];
  customize_attribute: Array<customizeAttributeList>;
  masters: any = [];
  master_val = [];
  measure_unit_type: any = [];
  years = [];
  resource_access: any;
  linked_master : any = [];
  active_field:any = {
    id:null,
    label : null
  };
  image_upload:boolean = false;
  month = [
    {
      "id": 1,
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "id": 2,
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "id": 3,
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "id": 4,
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "id": 5,
      "abbreviation": "May",
      "name": "May"
    },
    {
      "id": 6,
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "id": 7,
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "id": 8,
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "id": 9,
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "id": 10,
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "id": 11,
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "id": 12,
      "abbreviation": "Dec",
      "name": "December"
    }
  ];
  selected_Id: number;
  propertyForm: UntypedFormGroup;
  property_detail: any = null;
  is_submited = false;
  child_master_val: any = {};
  assignment_detail: any;
  loader: boolean = false;
  rejected_attribute: any;
  rejectmessage: string = '';
  selected_obj: string = '';
  currentDate = new Date();
  max_date : string;
  min_date:string = "1970-01-01"
  //multiselect popup related variables===========================================================================================
  selectedValues: any = [];
  masterIDs: any = [];
  //multiselect popup related variables===========================================================================================
  @Output() notif: EventEmitter<string> = new EventEmitter<string>();
  measure_unit_control: number[] = [];
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<QuickEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private share: ShareService, private route: Router, private api: ApiserviceService, private fb: UntypedFormBuilder, private _sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private datePipe: DatePipe, private location: Location, private elementRef: ElementRef, private notify: NotificationService, private plocation: PlatformLocation) {
      // dialogRef.disableClose = true;
    this.mobile = this.share.mobile;
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
    let date = new Date();
    let current_year = date.getFullYear();
    let start = current_year - 30;
    this.max_date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    for (start; start < current_year + 30; start++) {
      this.years.push(start);
    }

    this.selected_obj = this.share.selected_obj_uid;
    this.selected_property = this.share.block0_obj.selected_property;
    if (this.share.block0_obj.entity_type == "property") {
      this.selected_Id = this.share.block0_obj.selected_property.id;
      this.resource_access = this.share.block0_obj.property_access;

      this.attribute_list = this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list);
      this.rejected_attribute = this.share.block0_obj.rejectedAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_floor") {
      this.selected_Id = this.share.floor_obj.selected_floor_id;
      this.resource_access = this.share.block0_obj.floor_access;
      this.attribute_list = this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list_floor);
      this.rejected_attribute = this.share.floor_obj.rejectedFloorAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_unit") {
      if(this.share.unit_obj && this.share.unit_obj.selected_unit){
        this.selected_Id = this.share.unit_obj.selected_unit.id;
      }
      this.resource_access = this.share.block0_obj.unit_access;
      this.attribute_list = this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list_unit);
      this.rejected_attribute = this.share.unit_obj.rejectedUnitAttributeList;

    }
    this.attribute_list = JSON.parse(JSON.stringify(this.attribute_list));
    this.setPropertyDetail();
    this.freezAttribute();
    this.createFormGroup();
    this.propertyForm = this.createFormElement();
    this.getMeasureUnitType();
    this.share.block0_obj.getAllowResource();
  }

  switchImage(){
    this.image_upload = true;
  }
  backFormSec(){
    this.image_upload = false;
  }
  focusPicker(picker){
    picker.open();
  }
  // functions added after implementing multi-select============================================================================
  getSelectedValues(attrib, single = false) {//getting selected values for multiselect dropdown
    let selected = this.propertyForm.get('input_' + attrib.id).value;
    if (!single) {
      if (selected && selected.length) {
        this.selectedValues.push({
          attr_id: attrib.id,
          selected: selected
        })
        $('#input_custom_' + attrib.id).val(selected.length + " values selected");
      } else {
        $('#input_custom_' + attrib.id).val("No values selected");
      }
    } else if (selected && single) {
      let valObj = this.fnc.getArrayValue('id', selected, this.master_val);
      if (valObj != null) {
        let val = (valObj.label) ? valObj.label : valObj.master_name;
        if (val) $('#input_custom_' + attrib.id).val(val);
      }
    } else {
      $('#input_custom_' + attrib.id).val("No values selected");
    }
  }

  openMultiSelect(attrib, parent) {
    let selected;
    if(parent){
      selected = this.propertyForm.get('input_' + parent.id).value;
      if(Array.isArray(selected) && selected.length == 0){
        this.notify.notify('Please select a parent value', 'warn');
        return;
      }else{
        if(!selected){
          this.notify.notify('Please select a parent value', 'warn');
          return;
        }
      }
    }

    let dialogRef = this.dialog.open(QuickEditMultiselectComponent, {
      width: '450px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: 'create-master-panel',
      data: {
        source: this,
        attrib: attrib,
        parent: parent,
        selected: this.selectedValues
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.createProperty(this.propertyForm, false);
    });
  }

  getMasterValues(v, masterValueIds = null) {
    this.searchTerm = '';
    this.confirmation = false
    let m_val = this.getMasterById(v);
    //if(m_val.length > 0 && masterValueIds == null) return;
    if (masterValueIds == null) return;

    let url = this.share.block0_obj.entity_type + "/masters/" + v + "/values?sort_by=master_value_name&limit=100&offset=0&status=1";
    if (this.share.block0_obj.property_type_id && this.share.block0_obj.entity_type == "property_unit") {
      url += "&property_type_ids=" + this.share.block0_obj.property_type_id;
    }
    if (masterValueIds) {
      url += '&master_value_ids=' + masterValueIds.toString();
    }
    let curMaster: any;
    this.api.getEmsData(url)
      .subscribe((data: any) => {
        data.forEach(element => {
          let val = {
            label: element.master_value_label[this.api.language],
            labels: element.master_value_label,
            master_id: v,
            master_name: element.master_value_name,
            id: element.master_value_id,
            status: element.status,
            property_type_id: element.property_type_id,
            master_value_id: element.parent_master_value_id,
            parent_master_value_ids: element.parent_master_value_ids,
            name: element.master_value_label[this.api.language] // adding this for drop down
          };
          curMaster = this.fnc.getArrayValue('id', val.id, m_val);
          if (curMaster == null) {
            this.master_val.push(val);
          } else {
          }
        });
      });
  }
  close(){
    this.dialogRef.close();
  }
  // functions added after implementing multi-select============================================================================


  //frezz all attribute in case of rejected
  freezAttribute() {
    let is_old = false;
    if (this.property_detail) {
      if (this.property_detail.status != 1) is_old = true;
    }
    if ((this.selected_property.status == 4 || this.selected_property.status == 6) && is_old) {
      this.attribute_list.forEach((e, k) => {
        e.is_editable = false;
        if (this.fnc.getArrayValue('keyId', e.id, this.rejected_attribute)) {
          e.is_editable = true;
        }
      });
    }
  }


  gotogroup(){
    if (this.mobile) {
      if(this.share.block0_obj.entity_type == 'property') this.route.navigate(['groups']);
      if (this.share.block0_obj.entity_type == 'property_floor') this.route.navigate(['floorGroup']);
      if (this.share.block0_obj.entity_type == 'property_unit') this.route.navigate(['unitGroup']);
      this.dialogRef.close();
    }else{
      if(this.share.block0_obj.entity_type == 'property'){
        this.route.navigateByUrl('property/' + this.assignment_detail.assignment_id);
        this.dialogRef.close();
          setTimeout(() => {
            //this.share.block0_obj.openOptions(this.property_detail, false);
          }, 1000);
      }

    }
  }
  setPropertyDetail() {
    if (this.share.block0_obj.entity_type == 'property') {
      this.property_detail = this.share.block0_obj.property_detail;
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      this.property_detail = this.share.floor_obj.floor_detail;
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      this.property_detail = this.share.unit_obj.unit_detail;
    }
  }
  createFormElement() {
    if (this.share.block0_obj.table_master_val.length > 0) {
      this.master_val = this.share.block0_obj.table_master_val;
    }

    let group = this.fb.group({});
    let tbl_attr = [], table_attr = [];

    this.customize_attribute.forEach(
      control => {
        if (control.data_type == 'table') { // for type table
          let tbl = { id: control.id, child: [] };
          tbl_attr.push(control.id);
          if (control.child.length > 0) {
            control.child.forEach((attr) => {
              tbl_attr.push(attr.id);
            });
            tbl.child = control.child;
          }
          table_attr.push(tbl);
        }
        if (tbl_attr.indexOf(control.id) == -1) {
          group.addControl('input_' + control.id, this.fb.control(''));
          if (control.measure_unit) group.addControl('unit_' + control.id, this.fb.control(''));
          if (control.child.length > 0) { // control for child attributes
            control.child.forEach(child => {
              group.addControl('input_' + child.id, this.fb.control(''));
              if (child.measure_unit) group.addControl('unit_' + child.id, this.fb.control(''));
              if (child.mutual.length > 0) { // control for child mutual attributes
                setTimeout(() => {
                  //this.hideElement(control.id); // hide child element for mutual exclussive
                }, 200);
                child.mutual.forEach(cm => {
                  group.addControl('input_' + cm.id, this.fb.control(''));
                  if (cm.measure_unit) group.addControl('unit_' + cm.id, this.fb.control(''));
                });
              }
            }
            );
          }
          if (control.mutual.length > 0) { // control for mutual exclussive attributes
            control.mutual.forEach(mutual => {
              if (mutual) {
                group.addControl('input_' + mutual.id, this.fb.control(''));
                if (mutual.measure_unit) group.addControl('unit_' + mutual.id, this.fb.control(''));
              }
            });
          }
        }
         if(control.measure_unit !== null){
          this.measure_unit_control.push(control.id);
         }
      }
    );

    table_attr.forEach((v) => { // form element for table type
      group.addControl('input_' + v.id, this.fb.control(''));
      let attrib_detail = this.fnc.getArrayValue('id', v.id, this.attribute_list);
      let master_val = this.getMasterById(attrib_detail.master_id);
      master_val.forEach((m) => {
        v.child.forEach((c) => {
          group.addControl('input_' + m.id + "_" + c.id, this.fb.control(''));
          if (c.measure_unit) group.addControl('unit_' + m.id + "_" + c.id, this.fb.control(''));
        });
      });
    });
    return group;
  }
  isIdMatched(id: number): boolean {
    return this.measure_unit_control.includes(id);
  }
  checkChild(attr, event, type) {
    let child = attr.child, val, mutual;
    let e = $(this.elementRef.nativeElement);
    if (child) {
      val = e.find("#input_" + attr.id).val();
      if (type == 'checkbox') {
        if (!event.target.checked) val = false;
      }
      child.forEach((v) => {
        if (val) {
          e.find("#input_" + v.id).prop('disabled', false);
        } else {
          e.find("#input_" + v.id).prop('disabled', true);
        }
        mutual = v.mutual;
        if (mutual) {
          mutual.forEach((m) => {
            if (val) {
              e.find("#input_" + m.id).prop('disabled', true);
            } else {
              e.find("#input_" + m.id).prop('disabled', true);
            }
          })
        }

      });
    }
  }

  setAttributeValue(id, pid = null) { // not in use for now
    let result = null;
    if (this.property_detail) {
      let attribute = this.fnc.getArrayValue('id', id, this.attribute_list);
      let val = this.property_detail?.data;
      if (val[id]) {
        if (attribute.data_type != 'table') {
          // result = val[id];
          if (attribute.measure_unit) {
            //result = val[id].split(' ')[0];
          }
        }
      }
    }
    return result;

  }
  formatDate(value) {
    if(value){
      value = new Date(value);
     return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
    }
    return '';
  }

  patchFormData() {
    let e = $(this.elementRef.nativeElement);
    let data = {}, patchData = {}, pval;
    if (this.property_detail) {
      if (this.property_detail?.data) {
        data = this.property_detail?.data;
      }
    }
    if (data) {
      let val = null, key, unit_key, control;
      let rec = {}, unit_rec = {};
      this.attribute_list.forEach((v) => {
        if (!v.is_editable) {
          // control = this.propertyForm.get("input_"+v.id);
          // control.disabled();
          setTimeout(() => {
            e.find("#input_" + v.id).addClass("disabled-div");
            e.find("#unit_" + v.id).addClass("disabled-div");
            e.find("#span_" + v.id).addClass("disabled-div");
          }, 200);
        }
        let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
        if (data[v.id] && !v.is_hidden && v.status) {
          key = 'input_' + v.id;
          if (v.data_type != 'table') {

            if (v.measure_unit && String(data[v.id]).indexOf(' ') != -1) {
              let unit_data = data[v.id].split(' ');
              rec[key] = unit_data[0];
              if (unit_data[1]) {
                unit_key = "unit_" + v.id;
                unit_rec[unit_key] = unit_data[1];
                this.propertyForm.patchValue(unit_rec);
              }

            } else {
              val = data[v.id];
              if (v.data_type == 'year' || v.data_type == 'month') { // convert each value to string
                val = String(val);
              }
              if (v.data_type == 'date') { // convert each value to string
                val = this.formatDate(val) //this.formatDate(new Date(val));

              }
              if (v.data_type == 'single-select') val = String(val);
              if (v.data_type == 'single-select' && attrib_detail) {
                val = String(val);
                if (attrib_detail.child.length) {
                  pval = val;
                  setTimeout(() => {
                    this.changeChild(pval, attrib_detail,true); // patch value of child element
                  }, 600);
                }
              }
              if (v.data_type == 'multi-select') { // convert each value of multi select to string
                if (val.length > 0) {
                  if (attrib_detail) { // patch value of child element
                    if (attrib_detail.child.length) {
                      pval = val;
                      setTimeout(() => {
                        this.changeMultiChild(pval, attrib_detail,true);
                      }, 600);
                    }
                  }
                  let multi_val = [];
                  val.forEach((multi) => {
                    multi_val.push(String(multi));
                  });
                  val = multi_val;
                } else {
                  val = null;
                }
              }
              rec[key] = val;
            }
            if (attrib_detail && attrib_detail.mutual.length) { // for mutual attribute condition

              if (data[attrib_detail.id]) {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
                }, 200);
                attrib_detail.mutual.forEach((mt) => {
                  patchData["input_" + mt.id] = null;
                  this.propertyForm.patchValue(patchData);
                });
              }
              if (attrib_detail.child.length) {
                attrib_detail.child.forEach((c) => {
                  if (data[c.id]) {
                    setTimeout(() => {
                      e.find("#radio_" + c.id + "_1").prop('checked', true);
                    }, 200);
                  }
                  if (c.mutual.length) {
                    c.mutual.forEach((m) => {
                      if (data[m.id]) {
                        setTimeout(() => {
                          e.find("#radio_" + c.id + "_2").prop('checked', true);
                          e.find("#div_" + c.id + "_1").addClass("disabled-div");
                          e.find("#input_" + c.id).addClass("disabled-div");
                          e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                        }, 200);
                      }
                    })
                  }
                })
              }
            }
            this.propertyForm.patchValue(rec);
          }
          if (v.data_type == 'table') {
            if (data[v.id].length > 0) {
              let record = data[v.id];
              let master_val = this.getMasterById(v.master_id);
              let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
              master_val.forEach((m) => {
                if (attrib_detail.child.length > 0) {
                  let attr_m = this.fnc.getArrayValue('0', m.id, record);
                  if (!attr_m) return;
                  attrib_detail.child.forEach((c) => {
                    key = 'input_' + m.id + '_' + c.id;
                    if (!c.is_hidden && c.status) {
                      if (!c.is_editable) {
                        // control = this.propertyForm.get("input_"+v.id);
                        // control.disabled();
                        setTimeout(() => {
                          e.find('#input_' + m.id + '_' + c.id).addClass("disabled-div");
                        }, 200);
                      }
                      if (c.measure_unit && String(attr_m[c.id]).indexOf(' ') != -1) {
                        let unit_data = attr_m[c.id].split(' ');
                        rec[key] = unit_data[0];
                        if (unit_data[1]) {
                          unit_key = 'unit_' + m.id + '_' + c.id;
                          unit_rec[unit_key] = unit_data[1];
                          this.propertyForm.patchValue(unit_rec);
                        }
                      } else {
                        val = attr_m[c.id];
                        if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to string
                          val = String(val);
                        }
                        if (c.data_type == 'multi-select') { // convert each value of multi select to string
                          if (val) {
                            if (val.length > 0) {
                              let multi_val = [];
                              val.forEach((multi) => {
                                multi_val.push(String(multi));
                              });
                              val = multi_val;
                            } else {
                              val = null;
                            }
                          }
                        }
                        rec[key] = val;
                      }

                    }
                  });
                }
              });
              this.propertyForm.patchValue(rec);
            }
          }
        } else {

          if (attrib_detail && attrib_detail.mutual.length) {
            attrib_detail.mutual.forEach((m) => {
              if (data[m.id]) {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_2").prop('checked', true);
                  this.hideElement(attrib_detail.id);
                  e.find("#div_" + attrib_detail.id + "_1").addClass("disabled-div");
                  e.find("#input_" + attrib_detail.id).addClass("disabled-div");
                  e.find("#div_" + attrib_detail.id + "_2").removeClass("disabled-div");
                  patchData["input_" + attrib_detail.id] = null;
                  this.propertyForm.patchValue(patchData);
                }, 300);

              } else {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
                }, 300);
              }
            });
            if (attrib_detail.child.length) {
              attrib_detail.child.forEach((c) => {
                if (data[c.id]) {
                  setTimeout(() => {
                    e.find("#radio_" + c.id + "_1").prop('checked', true);
                  }, 200);
                }
                if (c.mutual.length) {
                  c.mutual.forEach((m) => {
                    if (data[m.id]) {
                      setTimeout(() => {
                        e.find("#radio_" + c.id + "_2").prop('checked', true);
                        e.find("#div_" + c.id + "_1").addClass("disabled-div");
                        e.find("#input_" + c.id).addClass("disabled-div");
                        e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                      }, 200);
                    } else {
                      setTimeout(() => {
                        e.find("#radio_" + c.id + "_1").prop('checked', true);
                      }, 200);
                    }
                  })
                }
              })
            }
          }

          // patch default value
          if (v.data_type != "table") {
            key = "input_" + v.id;
            unit_rec[key] = v.default_value;
            this.propertyForm.patchValue(unit_rec);
            if (v.measure_unit) {
              unit_key = "unit_" + v.id;
              rec[unit_key] = v.measure_unit;
              this.propertyForm.patchValue(rec);
            }
          }

        }
        // patch default value of table
        if (v.data_type == 'table') {
          let record = data[v.id];
          let master_val = this.getMasterById(v.master_id);
          let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
          if (attrib_detail) {
            master_val.forEach((m) => {
              let attr_m = this.fnc.getArrayValue('0', m.id, record);
              if (attrib_detail.child.length > 0) {
                attrib_detail.child.forEach((c) => {
                  if (!c.is_hidden && c.status) {
                    if (c.measure_unit) {
                      unit_key = 'unit_' + m.id + '_' + c.id;
                      unit_rec[unit_key] = c.measure_unit;
                      this.propertyForm.patchValue(unit_rec);
                      if((attr_m && !attr_m[c.id]) || !attr_m) {
                        key = 'input_' + m.id + '_' + c.id;
                        rec[key] = c.default_value;
                        this.propertyForm.patchValue(rec);
                      }
                    } else if((attr_m && !attr_m[c.id]) || !attr_m) {
                      key = 'input_' + m.id + '_' + c.id;
                      rec[key] = c.default_value;
                      this.propertyForm.patchValue(rec);
                    }
                  }
                });
              }
            });
          }

        }
      });
    }
  }

  createProperty(event, isClosed:boolean=true) {
    this.is_valid = true;
    if (!this.resource_access.PATCH) {
      if (this.share.block0_obj.entity_type == "property") this.notify.notify("You are not authorized to update property", 'warn');
      if (this.share.block0_obj.entity_type == "property_floor") this.notify.notify("You are not authorized to update property floor", 'warn');
      if (this.share.block0_obj.entity_type == "property_unit") this.notify.notify("You are not authorized to update property unit", 'warn');
      return;
    }
    let updatedAttribute = 0;
    let preVal = [];
    if (this.property_detail) {
      if (this.property_detail?.data) {
        preVal = this.property_detail?.data;
      }
    }
    let value = event.value;
    let x, attr_id, unit = '', attribute_c, attribute, lang_attribute = {}, simple_attribute = {}, val;
    let data = {}, body = {}, used_attr = [], table_attr = [];
    let e = $(this.elementRef.nativeElement);
    for (x in value) {
      if (x.indexOf('unit') == -1) {
        unit = '';
        let attr_array = x.split('_');
        let attr_id = attr_array[attr_array.length - 1]; // last index wil be attribute id
        e.find("#input_" + attr_id).removeClass("red-border");
        attribute_c = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
        attribute = this.fnc.getArrayValue('id', attr_id, this.attribute_list);
        if (attribute.is_editable && !attribute.is_hidden && attribute.status) {
          if (attribute.data_type == 'table') { // for type table
            let tbl = { id: attribute_c.id, child: [] };
            used_attr.push(attribute_c.id);
            if (attribute_c.child.length > 0) {
              attribute_c.child.forEach((attr) => {
                used_attr.push(attr.id);
              })
              tbl.child = attribute_c.child;
            }
            table_attr.push(tbl);
          }
          if (used_attr.indexOf(+attr_id) == -1) {
            if (attribute.measure_unit) {
              e.find("#unit_" + attr_id).removeClass("red-border");
              unit = value["unit_" + attr_id];
              val = null;
              if (unit && value[x]) val = value[x] + " " + unit;
              if ((!unit || !value[x]) && attribute.is_mandatory) {
                e.find("#unit_" + attr_id).addClass("red-border");
                e.find("#input_" + attr_id).addClass("red-border");
                this.is_valid = false;
                val = null;
              }
            } else {
              val = value[x];
              if (!val && attribute.is_mandatory) {
                e.find("#input_" + attr_id).addClass("red-border");
                this.is_valid = false;
              }
            }
            if (attr_id == this.share.block0_obj.property_name_attr_id && val) {
              let _building_name = { [this.api.language.toString()]: val };
              body['own_address'] = {
                'building_name': _building_name
              }; // synching own address
            }
            // if ((attribute.data_type == 'string-input') && val) {
            //   val = val.trim();
            // }
            let oldVal = (preVal[attr_id]) ? preVal[attr_id] : null;
            if (attribute.data_type == "toggle" && !val) {
              val = false;   // set false value to toggel in case of null value
              if (!oldVal) oldVal = false;
            }
            if (attribute.data_type == "date" && !val) {
              val = null;   // set false value to toggel in case of null value
            }
            if (attribute.data_type == "date" && val) {
              val = this.formatDate(new Date(val));

            }
            if ((attribute.data_type == "toggle" && val != oldVal) || this.compairValue(val, oldVal)) { // send only changed value
              //updatedAttribute++;
              if (attribute.data_type == 'multi-select') { // convert each value to integer
                if (val.length > 0) {
                  let multi_val = [];
                  val.forEach((multi) => {
                    if(multi) multi_val.push(parseInt(multi));
                  });
                  val = multi_val;
                } else {
                  val = null;
                }
              }
              if (attribute.data_type == 'year' || attribute.data_type == 'month') { // convert each value to integer
                val = parseInt(val);
              }
              if (attribute.is_mandatory && !val) {  // null value is not allow in mandatory case

              } else {
                if (attribute.is_lang_dependent) {
                  lang_attribute[attr_id] = val;
                }
                if (!attribute.is_lang_dependent) {
                  simple_attribute[attr_id] = val;
                }
              }
            }

          }
        }
      }
      // this.dialogRef.close();
    }
    if (table_attr.length > 0) { // get data of table type attribute
      table_attr.forEach((v) => {
        let tbl_val = [];
        let attrib_detail = this.fnc.getArrayValue('id', v.id, this.attribute_list);
        let master_val = this.getMasterById(attrib_detail.master_id);
        let record = preVal[v.id];
        master_val.forEach((m) => {
          let tbl = {};
          let attr_m = this.fnc.getArrayValue('0', m.id, record);
          tbl["0"] = m.id;
          v.child.forEach((c) => {
            if (c.is_editable && !c.is_hidden && c.status) {
              if (c.measure_unit) {
                e.find("#unit_" + m.id + "_" + c.id).removeClass("red-border");
                val = value["input_" + m.id + "_" + c.id];
                if(val){
                  unit = value["unit_" + m.id + "_" + c.id];
                  val = val + " " + unit;
                }else{ // make it null if no value is set
                  val = null;
                }
                if ((!unit || !value["input_" + m.id + "_" + c.id]) && attribute.is_mandatory) {
                  e.find("unit_" + m.id + "_" + c.id).addClass("red-border");
                  this.is_valid = false;
                  val = null;
                }
              } else {
                val = value["input_" + m.id + "_" + c.id];
                if (!val && attribute.is_mandatory) {
                  e.find("input_" + m.id + "_" + c.id).addClass("red-border");
                  this.is_valid = false;
                }
              }
              if ((attribute.data_type == 'string-input') && val) {
                val = val.trim();
              }
              //let oldVal = (attr_m[c.id]) ? attr_m[c.id] : null;
              if (val != '' || val != null){//  && this.compairValue(val,oldVal)
                //updatedAttribute++;
                if (c.data_type == 'multi-select') { // convert each value to integer
                  if (val.length > 0) {
                    let multi_val = [];
                    val.forEach((multi) => {
                      if(multi) multi_val.push(parseInt(multi));
                    });
                    val = multi_val;
                  } else {
                    val = null;
                  }
                }
                if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to integer
                  val = parseInt(val);
                }
                if (c.data_type == 'number-input' && !c.measure_unit) {
                  tbl[c.id] = + val;
                } else {
                  tbl[c.id] = val;
                }
              }
            }
          });
          if (this.fnc.jsonLength(tbl) > 1) tbl_val.push(tbl);
        });
        if (attrib_detail.is_lang_dependent && tbl_val.length) {
          lang_attribute[v.id] = tbl_val;
        }
        if (!attrib_detail.is_lang_dependent && tbl_val.length) {
          simple_attribute[v.id] = tbl_val;
        }
      });
    }
    if (this.fnc.jsonLength(lang_attribute) || this.fnc.jsonLength(simple_attribute)) updatedAttribute = 1;
    if (updatedAttribute) {
      data["0"] = simple_attribute;
      data[this.api.language] = lang_attribute;
      body["data"] = data;
      if (this.property_detail.status == 4) {
        body["status"] = 6;
        if (this.property_detail.attribute_info) {
          for (let key in this.property_detail.attribute_info) {
            if (this.property_detail.attribute_info[key].status == -1) { // -1 is rejected, 3 edited by user.
              this.property_detail.attribute_info[key].status = 3;
            } else {
              this.property_detail.attribute_info[key].status = 1;
            }
          }
          body["attribute_info"] = this.property_detail.attribute_info;
        }
      }
      body["updated_by"] = this.api.user_id;
      body["assignment_id"] = this.share.block0_obj.assignment_id;
      let url = "";
      if (this.share.block0_obj.entity_type == 'property') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.selected_Id;
      }
      if (this.share.block0_obj.entity_type == 'property_floor') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.selected_Id;
      }
      if (this.share.block0_obj.entity_type == 'property_unit') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id + "/units/" + this.selected_Id;
      }
      this.share.main_property_obj.gLoader = true;
      this.api.patchEpsData(url, body)
        .subscribe({next: (data:any) => {
          this.share.main_property_obj.gLoader = false;
          this.notify.notify('Information Updated', 'success');
          if (this.share.block0_obj.entity_type == 'property') {
            this.share.block0_obj.getPropertyDetail();
          }
          if (this.share.block0_obj.entity_type == 'property_floor') {
            this.share.floor_obj.getFloorDetail();
          }
          if (this.share.block0_obj.entity_type == 'property_unit') {
            this.share.unit_obj.getUnitDetail();
          }
          setTimeout(() => {
            this.setPropertyDetail();
            this.syncWebView(simple_attribute, lang_attribute);
          }, 3000);
          if(isClosed) this.closeDialog();
        },
          error:(err) => {
            // this.notify.notify(err.error.message, 'error');
            this.share.main_property_obj.gLoader = false;
          }});
    }
  }

  syncWebView(simple_attribute, lang_attribute) {//Update list UI with current data
    if (this.mobile) {
      return;
    }
    let valObj, index;
    if (this.share.block0_obj.entity_type == 'property') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.block0_obj.property_list_custom);
      valObj = this.share.block0_obj.property_list_custom[index];
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.floor_obj.floorData);
      valObj = this.share.floor_obj.floorData[index];
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.unit_obj.unitData);
      valObj = this.share.unit_obj.unitData[index];

    }
    if (!valObj) return;
    this.attribute_list.forEach((v) => {
      if (v.is_listable && (v.data_type != 'table' && v.data_type != 'single-select' && v.data_type != 'multi-select')) {
        let data = this.property_detail?.data;
        if (data[v.id] != undefined && valObj[v.name] != undefined) {
          valObj[v.name] = data[v.id];
        }
      }
    })
  }

  compairValue(val1, val2) {
    let res = true;
    if (!val1 && !val2) res = false;
    if (val1 && val2) {
      if (val1.toString() == val2.toString()) res = false;
    }
    return res;
  }

  // grouping attributes
  public createFormGroup() {
    let val = {}, new_list = [];
    let mtl = [];
    this.masterIDs = [];
    this.attribute_list.forEach((v) => {
      if (v['master_id']) {
        this.masterIDs.push({
          master_id: v.master_id,
          attribute_id: v.id
        })
      }
      if (this.mobile) {
        if (v.data_type == 'images' || v.data_type == 'videos') {
          this.route.navigateByUrl('uploadMedia');
          return;
        }
      } else {
        if (v.data_type == 'images' || v.data_type == 'videos') {
          if (this.share.floor_obj) {
            if (this.share.floor_obj.selected_floor) {
              setTimeout(() => {
                this.notif.emit('upload_Media_unit');
              }, 200);
            }
          }
          setTimeout(() => {
            this.notif.emit('uploadMedia');
          }, 400);
          return;
        }
      }
      val = {
        id: v.id,
        name: v.name,
        parent_id: v.parent_id,
        label: v.label,
        data_type: v.data_type,
        measure_type: v.measure_type,
        measure_unit: v.measure_unit,
        default_value: v.default_value,
        is_mandatory: v.is_mandatory,
        is_hidden: v.is_hidden,
        is_editable: v.is_editable,
        status: v.status,
        mutually_exclusive: v.mutually_exclusive,
        master_id: v.master_id,
        has_linked_master : v.has_linked_master,
        dependent: v.dependent,
        mutual: [],
        child: []
      }
      new_list.push(val);
    });
    new_list.forEach((v) => {
      // Check if mutually_exclusive is an array before calling forEach
      if (Array.isArray(v.mutually_exclusive)) {
        v.mutually_exclusive.forEach((m) => {
          this.used_attribute.push(m);
          mtl.push(this.fnc.getArrayValue('id', m, new_list));
        });
        v.mutual = mtl;
      }

      if (v.parent_id) {
        this.used_attribute.push(v.id);
        let index = this.fnc.getArrayindex('id', v.parent_id, new_list);
        if (index !== null) {
          new_list[index].child.push(v);
        }
      }
    });
    this.group_attribute = new_list;
    this.customize_attribute = this.fnc.getDeletedArray('id', this.used_attribute, new_list); // remove duplicate list
  }
  // grouping aatributes end

  changeMultiChild(event, attrib, onload = false) {
    let val = event;
    let fld = null;
    let last_val: any = [];
    let new_val: any = [];
    let final_val: any = [];
    if (val) {
      if (attrib.child.length) {
        attrib.child.forEach((child) => {
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.linked_master = [];
            val.forEach((v) => {
              fld = null;
              if (this.child_master_val[child.id].length) {
                last_val = this.child_master_val[child.id];
              }
              new_val = this.getMasterByValueId(child, +v);
              if (new_val.length) {
                final_val = new_val;
              }
              if (last_val.length) {
                final_val = last_val;
              }
              if (last_val.length && new_val.length) {
                final_val = new_val.concat(last_val);
              }
              this.child_master_val[child.id] = final_val;
              if (child.has_linked_master) {
                this.linked_master = this.fnc.removeDuplicateKey(this.linked_master);// remove duplicate value
                fld = 'input_' + child.id;
                if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {
                  if(child.data_type == "single-select"){
                    this.propertyForm.patchValue({ [fld]: this.linked_master.length > 0 ? this.linked_master[0]: ''});
                  }else if(child.data_type == "multi-select") {
                    this.propertyForm.patchValue({ [fld]: this.linked_master });
                  }
                }
              }
            });
          }
        });
      }
    }
  }

  // addNewMasterValue(value,attrib,parent){
  //   this.addOtherOption = true;
  //   let parent_master_value_id = null;
  //   if(parent){
  //     parent_master_value_id = this.propertyForm.get('input_'+parent.id).value;

  //   }
  //   let dialogRef = this.dialog.open(QuickConfirmExit, {
  //     width: '350px',
  //     panelClass: 'create-master-panel',
  //     data: this
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if(this.confirmation){
  //       this.loader1 = true;
  //       let property_type_ids,body,ms_value, url = this.share.block0_obj.entity_type+'/masters/'+attrib.master_id+'/values';
  //       property_type_ids = null;
  //       ms_value = value.toLowerCase();
  //       ms_value = ms_value.trim();
  //       if (this.share.block0_obj.entity_type == "property_unit") {
  //         property_type_ids = [this.share.unit_group_obj.selected_unit.property_type_id];
  //       }
  //         body = {
  //           "added_by": this.api.user_id,
  //           "master_value_label": {
  //             [this.api.language]: value
  //           },
  //           "master_value_name": ms_value.replace(/ /g,"_"),
  //           "parent_master_value_id": parent_master_value_id,
  //           "property_type_ids": property_type_ids,
  //           "sort_order": 0,
  //           "status": 1
  //         }
  //         this.api.postEmsData(url,body).subscribe((data:any)=>{
  //           this.loader1 = false;
  //           this.confirmation = false;
  //           let res = data.json();
  //           let val ={
  //             master_id: attrib.master_id,
  //             name: body.master_value_name,
  //             label:value,
  //             id: res.master_value_ids[0],
  //             status: 1,
  //             property_type_id: body.property_type_ids,
  //             master_value_id: body.parent_master_value_id,
  //             parent_master_value_ids : body.parent_master_value_id+''
  //           }
  //           this.master_val.push(val);
  //           if(parent) this.changeChild(parent_master_value_id,parent);
  //           this.notify.notify("Value successfully added!", 'Dismiss');
  //         },err=>{
  //           this.loader1 = false;
  //           this.confirmation = false;
  //         });
  //     }
  //   });
  // }

  addNewMasterValue(value, attrib, parent, selectedMaster = null) {
    let e = $(this.elementRef.nativeElement);
    this.addOtherOption = true;
    let parent_master_value_id = null;
    if (selectedMaster) {
      parent_master_value_id = selectedMaster;
    }
    if (parent && !selectedMaster) {
      parent_master_value_id = this.propertyForm.get('input_' + parent.id).value;
    }
    let dialogRef = this.dialog.open(QuickConfirmExit, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: this
    });
    dialogRef.afterClosed().subscribe(result => {
      this.searchTerm = null;
      this.addOtherOption = false;
      // this.active_field = {
      //   id: null,
      //   label: null
      // };
      if (this.confirmation) {
        this.loader1 = true;
        let property_type_ids, body, ms_value, url = this.share.block0_obj.entity_type + '/masters/' + attrib.master_id + '/values';
        property_type_ids = null;
        if (this.share.block0_obj.entity_type == "property_unit") {
          let p_type_id = this.fnc.getArrayValue('name',this.share.unit_obj.selected_unit.property_type_name,this.share.unit_obj.property_type_list);
          property_type_ids = [p_type_id.id];
        }
        ms_value = value.toLowerCase();
        ms_value = ms_value.trim();
        body = {
          "added_by": this.api.user_id,
          "master_value_label": {
            [this.api.language]: value
          },
          "master_value_name": ms_value.replace(/ /g, "_"),
          "parent_master_value_id": parent_master_value_id,
          "parent_master_value_ids": parent_master_value_id,
          "property_type_ids": property_type_ids,
          "data_source": 3,
          "sort_order": 0,
          "status": 1
        }
        let rec = {};
        this.api.postEmsData(url, body).subscribe({
          next: (data: any) => {
          if (attrib.data_type == 'single-select') {
            let childrens = this.fnc.getChildArray('parent_id', attrib.id, this.attribute_list);
            this.selectedValues = [];
            if (childrens != null) {
              childrens.forEach(ch => {
                this.propertyForm.patchValue({ ['input_' + ch.id]: null });
              });
            }
          }
          this.loader1 = false;
          this.confirmation = false;
          let res = data;
          let val = {
            master_id: attrib.master_id,
            master_name: body.master_value_name,
            label: value,
            id: res.master_value_ids[0],
            status: 1,
            property_type_id: body.property_type_ids,
            master_value_id: body.parent_master_value_id,
            parent_master_value_ids: body.parent_master_value_id + '',
            name: value // added for drop down
          }
          this.master_val.push(val);

          if (attrib.data_type == 'single-select') {

            rec['input_' + attrib.id] = val.id.toString();
            this.propertyForm.patchValue(rec);
            setTimeout(() => {
              if (!parent) this.changeChild(val.id, attrib);
            }, 100);

          }
          if (attrib.data_type == 'multi-select') {
            let values: any = [];
            if (this.propertyForm.get('input_' + attrib.id).value) {
              values = this.propertyForm.get('input_' + attrib.id).value;
            }
            values.push(val.id);
            rec['input_' + attrib.id] = values;
            this.propertyForm.patchValue(rec);
          }
          e.find("#input_" + attrib.id).click(); // to show value
          e.find("#child_" + attrib.id).css('border', '1px solid red');
          this.notify.notify("Value successfully added!", 'success');
        }, error:(err) => {
          rec['input_' + attrib.id] = null;
          this.propertyForm.patchValue(rec);
          this.loader1 = false;
          this.confirmation = false;
          if(err && err['status'] == 401){
            this.notify.notify(err.error.message, 'error');
          }
        }});
      } else {
        let tag = 'input_' + attrib.id
        this.propertyForm.patchValue({ [tag]: '' });
      }
    });
  }

  changeChild(event, attrib, onload = false) {
    let val = event;
    if (val == 'addNewOption') {
      return;
    }
    if (val) {
      if (attrib.child.length) {
        let fld = null;
        attrib.child.forEach((child) => {
          this.linked_master = [];
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.child_master_val[child.id] = this.getMasterByValueId(child, +val);
            if (child.has_linked_master) {
              fld = 'input_' + child.id;
              if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {
                if(child.data_type == "single-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master.length>0 ? this.linked_master[0] : '' });

                }else if(child.data_type == "multi-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master });

                }
              }
            }
          }
        });
      }
    }
  }

  getChildMasterValue(attrib, parent) {
    let res = [];
    let id = attrib.id;
    if (parent.data_type == "single-select" || parent.data_type == "multi-select") {
      if (this.child_master_val[id]) {
        res = this.child_master_val[id];
      }
      return res;
    } else {
      return this.getMasterById(attrib.master_id);
    }

  }

  getMasterById(master_id) {
    let record = [];
    record = this.fnc.getChildArray('master_id', master_id, this.master_val);
    return record;
  }

  getMasterByValueId(attrib, master_value_id) {
    let master_id = attrib.master_id;
    let record = [];
    let sub_master = this.fnc.getChildArray('master_id', master_id, this.master_val);
    if(attrib.has_linked_master){
      record = sub_master;
      sub_master.forEach((v)=>{
        if(v.parent_master_value_ids){
          let ms = v.parent_master_value_ids.split(",");
          if(ms.indexOf(''+master_value_id) > -1){
            this.linked_master.push((''+v.id).trim());
          }
        }
      });
    }else{
      record = this.fnc.getChildArray('master_value_id', master_value_id, sub_master);
    }
    return record;
  }

  getMeasureUnit(val) {
    if (val) {
      let units = this.fnc.getArrayValue('unit_type', val, this.measure_unit_type);
      if (units) {
        return units.measure_unit;
      }
    }
    return [];
  }

  getUnitByType(val) {
    if (val) {
      this.api.getEmsData("attribute/" + val + "/measureunits")
        .subscribe(
          (data:any) => {
            this.measure_unit_type.push({
              unit_type: val,
              measure_unit: data
            });
            localStorage.setItem('measure_unit_type',JSON.stringify(this.measure_unit_type));
          }
        );
    }
  }
  getMeasureUnitType() {
    let units = JSON.parse(localStorage.getItem('measure_unit_type'))
    if(units){
      this.measure_unit_type = units;
      return;
    }
    let url = "attribute/measureunittypes";
    this.api.getEmsData(url).subscribe(
      {
        next: (data:any) => {
            data.forEach((v) => {
              this.getUnitByType(v);
            });
          },
      error:() => {}
    }
    );
  }

  showChild(attr, event = null, num) {
    let id = attr.id;
    let patchData = {};
    let e = $(this.elementRef.nativeElement);
    //this.propertyForm.controls.nam
    if (event.target.value == 'on') {
      e.find('#child_' + id).removeClass('hidden');
    } else {
      e.find('#child_' + id).addClass('hidden');
    }
    if (num == 1) {
      e.find("#div_" + id + "_2").addClass("disabled-div");
      e.find("#input_" + id).removeClass("disabled-div");
      e.find("#div_" + id + "_1").removeClass("disabled-div"); // special case for toggle
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', true);
        patchData["input_" + id] = true;
      } else {
        patchData["input_" + id] = null;
      }
      if (attr.mutual.length) {
        attr.mutual.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
        });
      }

    }
    if (num == 2) {
      e.find("#div_" + id + "_2").removeClass("disabled-div");
      e.find("#div_" + id + "_1").addClass("disabled-div");
      e.find("#input_" + id).addClass("disabled-div");
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', false);
        patchData["input_" + id] = false;
      } else {
        patchData["input_" + id] = null;
      }
      this.propertyForm.patchValue(patchData);
      if (attr.child.length) {
        attr.child.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
          if (c.mutual.length) {
            c.mutual.forEach((m) => {
              patchData["input_" + m.id] = null;
              this.propertyForm.patchValue(patchData);
            });
          }
        });
      }

    }
  }

  openDialog() {
    let property_resource = this.share.block0_obj.property_access;

    if(!property_resource.PATCH){
      this.notify.notify('You are not authorized to submit property', 'warn');
      return;
    }

    let dialogRef = this.dialog.open(SubmitPropertyDialogComponent, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  hideElement(id) {
    let e = $(this.elementRef.nativeElement);
    e.find('#child_' + id).addClass('hidden');
  }

  backClicked() {
    this.submitForm();
    this.is_submited = true;
    if (this.is_valid) {
      this.location.back();
    } else {
      let dialogRef = this.dialog.open(QuickConfirmExit, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: this
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  onChanges(): void {
    this.propertyForm.valueChanges.subscribe(val => {
      this.is_submited = false;
    });
    // for focus in case of mobile only
    if (this.mobile) {
      let e = $(this.elementRef.nativeElement);
      e.find(':input').on('focus', function () {
        setTimeout(() => {
          if(this.id) e.find("#" + this.id)[0].scrollIntoView();
        }, 350);
      });
    }
  }
  isRejected(id) {
    let attrib = this.fnc.getArrayValue('keyId', id, this.rejected_attribute);
    if (attrib != null) {
      if (attrib.keyId == id) {
        return true;
      }
    }
    return false;
  }
  setRejectedMessage(id) {

    let attrib = this.fnc.getArrayValue('keyId', id, this.rejected_attribute);
    if (attrib != null) {
      this.rejectmessage = attrib.comment;
    }
  }



  submitForm() {
    if (!this.is_submited) {
      this.is_submited = true;
      let e = $(this.elementRef.nativeElement);
      e.find("#property_form_btn").trigger('click');
    }
  }
  onResize(event) {
    let height = event.target.innerHeight - 100;
    this.dHeight = height;
    let e = $(this.elementRef.nativeElement);
    e.find('#pList').css('height', this.dHeight);
    e.find('#DynamicBlock').css('height', (this.dHeight - 50));
  }
  getAttributeValue() {
    let data = this.property_detail?.data;
    if(data){
      this.masterIDs.forEach((e) => {
        if (data[e.attribute_id] && _isNumberValue(data[e.attribute_id])) {
          this.getMasterValues(e.master_id, data[e.attribute_id]);
        }
      });
    }

  }
  ngOnInit() {
    setTimeout(() => {
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height', this.dHeight);
      e.find('#DynamicBlock').css('height', (this.dHeight - 450));
      e.find('#DynamicBlockmob').css('height', (this.dHeight - 300));
      this.onChanges();
    }, 10);
    this.plocation.onPopState(() => {
      if (!this.is_submited) {
        this.submitForm(); // save form when user press back
      }
    });
    this.setPropertyDetail();
    setTimeout(() => {
      this.patchFormData();
      if (this.share.block0_obj.table_master_val.length > 0) {
        this.master_val = this.share.block0_obj.table_master_val;
      }
    }, 500);
    if (!this.property_detail) {
      this.loader = true;
      setTimeout(() => {
        this.setPropertyDetail();
        if (this.property_detail) {
          this.patchFormData();
          this.loader = false;
        }
      }, 1500);
    }
    this.getAttributeValue();
  }

  // MAT SELECT SEARCH IMPLIMENTATION------------------------------------------------------
  find(str, attrib, parent = null) {
    if (str && str.trim() == '') return;
    this.active_field = attrib;
    let list;
    if (parent) {
      list = this.getChildMasterValue(attrib, parent)
    } else {
      list = this.getMasterById(attrib.master_id);
    }
    this.newlist = [];
    str = str.toLowerCase();
    if (str && list) {
      list.forEach((v) => {
        let res = v.label.toLowerCase();
        if (res.includes(str)) {
          if (v != undefined) {
            this.newlist.push(v);
          }
        }
      })
    }
  }
  getList(attrib, s, parent = null) {
    let l;
    if (parent) {
      l = this.getChildMasterValue(attrib, parent)
    } else {
      l = this.getMasterById(attrib.master_id);
    }

    if (!s || this.active_field.id != attrib.id) {
      return l;
    }
    if (this.newlist) {
      return this.newlist;
    } else {
      return l;
    }
  }
  newValue(attrib, s, parent = null) {
    let l,val, i, flag:boolean = false;
    if (parent) {
      l = this.getChildMasterValue(attrib, parent);
    } else {
      l = this.getMasterById(attrib.master_id);
    }
    if (s) {
      s = s.toLowerCase();
      s = s.trim();
      for(i = 0; i<l.length; i++){
        val=l[i];
        if(val.label.toLowerCase() == s){
          flag = false;
          break;
        }else{
          flag = true;
        }
      }
      return flag;
    } else {
      return flag;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // MAT SELECT SEARCH IMPLIMENTATION------------------------------------------------------
}

