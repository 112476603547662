import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Location } from '@angular/common';
@Component({
  selector: 'quick-confirm-exit',
  templateUrl: '../quick-confirm-exit.component.html',
  styleUrls: ['../quick-edit.component.css']
})
export class QuickConfirmExit {
  addOtherOption:boolean = false;
  searchTerm:string;
  active_field:any = {
    id:null,
    label : null
  };
  constructor(public dialogRef: MatDialogRef<QuickConfirmExit>, @Inject(MAT_DIALOG_DATA) public data: any, private location: Location) {
    this.addOtherOption = data.addOtherOption;
    this.searchTerm = data.searchTerm;
    this.active_field = data.active_field;
  }
  ngOnInit() {

  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  exit(): void {
    this.dialogRef.close();
    this.location.back();
  }
  confirm(){
    this.data.confirmation = true;
    this.dialogRef.close();
  }
}
