import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../../../src/app/services/notification.service";
import { ShareService } from "../../../../../../src/app/services/share.service";
import { FloorMapComponent } from "../../floor-map.component";

@Component({
  selector: "unit-paste-group",
  templateUrl: "./unit-paste.component.html",
  styleUrls: ["../../floor-map.component.css"],
})
export class UnitGroupList {
  parent: any;
  group_list: any;
  show_child: any;
  selected_group_id = [];
  noSelectedunit: number = 0;
  constructor(
    public dialogRef: MatDialogRef<FloorMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private share: ShareService,
    private notify: NotificationService
  ) {
    this.parent = this.data.pObj;
    this.group_list = this.parent.share.block0_obj.group_custom_list_unit;
    this.noSelectedunit = this.share.floor_map_obj.unitGisUidsForPaste.length;
  }
  ngOnInit() {}
  isActive(id) {
    if (this.show_child == id) {
      this.show_child = null;
      return;
    }
    this.show_child = id;
  }
  selectGroup(e, v) {
    if (e.checked) {
      this.selected_group_id.push(v);
    } else {
      this.selected_group_id.splice(this.selected_group_id.indexOf(v), 1);
    }
  }
  pasteUnit() {
    if (this.selected_group_id.length > 0) {
      this.parent.paste(this.selected_group_id);
      this.closeDialog();
    } else {
      this.notify.notify("please select attribute group", "warn");
      return;
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
