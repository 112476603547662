import { Component, OnInit, NgZone, HostListener, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ShareService } from "../../services/share.service";
import { Router } from "@angular/router";
import { ViewChild } from "@angular/core";
import { IndoorMap } from "../../map_api/indoormap";
import { LatLon } from "../../map_api/latlon";
import { Style } from "../../map_api/style";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../services/notification.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { ApiserviceService } from "../../services/apiservice.service";
import * as $ from "jquery";
import { EditUnitComponent } from "./edit-unit/edit-unit.component";
import { Subject } from "rxjs";
import { UnitGroupList } from "./components/unit-list-group/unit-list-group.component";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_copy2_patch</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_get</li>
 * </ol>
 *
 */

@Component({
  selector: "app-floor-map",
  templateUrl: "./floor-map.component.html",
  styleUrls: ["./floor-map.component.css"],
})
export class FloorMapComponent implements OnInit {

  tenantName = new Subject<any>();
  mobile: boolean = false;
  @ViewChild("map") mapElement: any;
  //@ViewChild('floorSwitcherContainer') floorSwitcherElement: any;
  selected_property: any;
  selected_floor: any;
  unit_list: Array<any> = [];
  map: any;
  unit_Uids: Array<any> = [];
  unitGisUidsForPaste: Array<any> = [];
  copied: any = "";
  drawingManager: any;
  circle: any;
  poly: any;
  polygonCords: any;
  geomNew: any;
  load: boolean = true;
  loader: boolean = false;
  reposition: boolean = true;
  targetUnitids = [];
  assignment_detail: any;
  enableDrawing: boolean = false;
  userShape: any;
  full_view: boolean;
  dragPosition = {x: 0, y: 20};
  selected_unit_uid: any;
  map_loader: boolean=false;
  constructor(
    private share: ShareService,
    private route: Router,
    private http: HttpClient,
    private notify: NotificationService,
    private _zone: NgZone,
    public dialog: MatDialog,
    private api: ApiserviceService,
    private fnc: CommonfunctionService
  ) {
    this.mobile = this.share.mobile;
    this.share.floor_map_obj = this;
    this.assignment_detail = JSON.parse(
      localStorage.getItem("assignment_detail")
    );
    if (!this.share.block0_obj) {
      this.route.navigateByUrl("/");
      return;
    } else {
      this.selected_property = this.share.block0_obj.selected_property;
    }

  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if(this.full_view) this.openFullView();
    }
}
  openFullView(){
    var matches:any = document.getElementsByClassName('floor-map-view');
    let mapclass =  document.getElementsByClassName('map-web');
    this.resetPosition();
    for (let i = 0; i < matches.length; i++) {
      if(matches.item(i).classList.contains('full-view')){
        matches.item(i).classList.remove('full-view');
        mapclass.item(0).classList.remove('h_100')
        this.full_view = false;
      }else{
        matches.item(i).classList.add('full-view');
        mapclass.item(0).classList.add('h_100')
        this.full_view = true;
      }
    }
  }
  resetPosition() {
    this.dragPosition = {x: 0, y: 0};
  }
  repositionbox() {
    this.reposition = false;
    setTimeout(() => {
      this.reposition = true;
    }, 100);
  }
  backClicked() {
    this.route.navigateByUrl("floor");
  }
  copy() {
    if (this.unit_Uids.length == 1) {
      this.copied = this.getUnitDetail(this.unit_Uids[0]);
      this.share.toolbox_obj.setUnitId();
      let property_type_id = this.fnc.getArrayValue(
        "name",
        this.copied.property_type_name,
        this.share.unit_obj.property_type_list
      );
      this.share.block0_obj.property_type_id = property_type_id.id;
      this.share.block0_obj.entity_type = "property_unit";
      this.share.block0_obj.getGroups();
      this.notify.notify("Unit - " + this.copied.uid + " copied", "warn");
    } else {
      this.notify.notify("Only one unit can be copy at a time", "warn");
    }
  }

  paste(groups) {
    let i = 0;
    for (i = 0; i < this.unitGisUidsForPaste.length; i++) {
      let data = this.getUnitDetail(this.unitGisUidsForPaste[i]);
      this.targetUnitids.push(data.id);
    }

    let url =
      "deadmin/" +
      this.assignment_detail.assignment_name +
      "/properties/" +
      this.share.block0_obj.selected_property.id +
      "/floors/" +
      this.selected_floor.id +
      "/units/" +
      this.copied.id +
      "/copy2";
    let body = {
      attribute_group_ids: groups,
      overwrite_existing: true,
      target_unit_ids: this.targetUnitids,
      updated_by: this.api.user_id,
    };
    let data1 = this.getUnitDetail(this.unitGisUidsForPaste[0]);
    if (this.copied.property_type_name == data1.property_type_name) {
      this.api.patchEpsData(url, body).subscribe({
        next: (data: any) => {
          this.load = true;
          this.notify.notify(
            "Unit - " +
              this.copied.uid +
              " pasted to " +
              this.unitGisUidsForPaste.length +
              " units",
            "warn"
          );
          this.copied = null;
          this.unit_Uids = [];
          this.unitGisUidsForPaste = [];
          this.map.getUnitLayer().revertToDefaultStyle();
          this.setColorunitLayout("1");
        },
        error: (err) => {
          this.load = true;
        }
      });
    }
  }
  ngAfterViewInit() {}
  ngOnInit() {
    this.selected_floor = this.share.floor_obj.selected_floor;
    this.getUnitData();
    setTimeout(() => {
      let dHeight = this.share.heightService;
      $("#map").css("height", dHeight);
      this.renderMap();
      this.drawingManager.setDrawingMode(null);
    }, 500);

    if (this.share.block0_obj) {
      this.share.block0_obj.entity_type = "property_unit";
    }

    this.share.unit_obj.getUnitAttributes(false);
  }

  openDialog(info: any = {}) {
    info.pObj = this;
    let dialogRef = this.dialog.open(UnitGroupList, {
      width: "350px",
      panelClass: "create-master-panel",
      data: info,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  //redirect from map on mobile and web
  redirectToGroup() {
    this.share.toolbox_obj.flagVal = 2;
    this.share.unit_obj.isFloorMap=false;
    let data = this.getUnitDetail(this.unit_Uids[0]);
    if (this.mobile) {
      if (this.unit_Uids.length == 1 && data) {
        this.unit_Uids = [];
        this.share.unit_obj.openOptions(data, true);
      }
    } else {
      if (this.unit_Uids.length == 1 && data) {
        this.share.floorMapDialogObj.close();
        this.share.unit_obj.openOptions(data, false);
      }
    }
  }

  unitEdit(info: any = {}) {
    if(this.unit_Uids.length != 1){
      this.notify.notify('Please select unit')
      return;
    }
    if(this.load == true && this.map_loader == false){
    }else{
      return;
    }
    info.pObj = this;
    let dialogRef = this.dialog.open(EditUnitComponent, {
      width: '450px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: "create-master-panel",
      data: info,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result');

    });
  }

  getUnitDetail(uid) {
    let data = null;
    if (this.unit_list.length) {
      //let unit_list = this.share.unit_obj.unitData.concat(this.share.unit_obj.unitDataGis);
      //let unit_list = this.unit_list;
      // data = this.fnc.getArrayValue("gid", gid, unit_list);
      data = this.unit_list.find(e=> e.uid == uid);
    }
    return data;
  }

  getUnitData() {
    //this.selected_unit_id = [];
    this.loader = true;
    this.unit_list = [];
    this.api
      .getEpsData(
        "deadmin/" +
          this.assignment_detail.assignment_name +
          "/properties/" +
          this.share.block0_obj.selected_property.id +
          "/floors/" +
          this.share.floor_obj.selected_floor_id +
          "/units?offset=0&limit=100000&status=1,2,3,4,5,6,7&get_latest_property=Y"
      )
      .subscribe(
        (data: any) => {
          if (data.length) {
            let s_price,
            shop_name,
              l_price,
              area,
              img_list,
              img_url,
              image,
              unit_no,
              vacant;
            data.forEach((e) => {
              s_price = null;
              (l_price = null),
                (area = null),
                (img_url = "https://goo.gl/HLhJAL"),
                (unit_no = "NA");
              vacant = true;
              if (e.data.sale_price) {
                s_price = e.data.sale_price;
              }
              if (e.data.lease_price) {
                l_price = e.data.lease_price;
              }
              if (e.data.unit_area) {
                area = e.data.unit_area;
              }
              if (e.data.is_vacant !== undefined) {
                vacant = e.data.is_vacant;
              }
              if (e.data?.shop_name !== undefined) {
                shop_name = e.data.shop_name?.replace(/_/g," ");
                shop_name = this.fnc.capitalize_each_word(shop_name);
              }

              if (e.data.unit_number) {
                unit_no = e.data.unit_number;
              }
              if (e.data.images) {
                img_list = e.data.images;
                if (img_list.length > 0) {
                  img_url = img_list[0][0];
                  image = this.fnc.getArrayValue("2", true, img_list); //get default property image
                  if (image) {
                    img_url = image[0];
                  }
                }
              }
              let fdata: any = {
                direction: null,
                unit_type: e.unit_type_label,
                currency: e.currency,
                sale_price: s_price,
                lease_price: l_price,
                unit_area: area,
                id: e.unit_id,
                uid: e.unit_uid,
                gid: e.unit_gis_uid,
                unit_type_name: e.unit_type_name,
                sort_order: e.sort_order,
                image: img_url,
                unit_number: unit_no,
                is_vacant: vacant,
                shop_name: shop_name,
                property_type_name: e.property_type_name,
                property_type_id: e.property_type_id,
                attribute_info: e.attribute_info,
                status: e.status,
                is_edited: e.is_edited,
              };
              if (fdata.uid) {
                this.unit_list.push(fdata);
              } else {
                //this.unitDataGis.push(fdata);
              }
            });
            this.loader = false;
            setTimeout(() => {
              this.setColorunitLayout("2");
            }, 2000);
          }else{
            this.loader = false;
          }
        },
      );
  }
  //POLYGON DRAWING STARTS HERE
  drawUnitPolygon(mode = null) {
    if (!mode) {
      this.share.toolbox_obj.setActive = "";
      return;
    }
    if (mode == "polygon") {
      this.clearDrawing();
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON
      );
      this.share.toolbox_obj.setActive = mode;
    }
    if (mode == "circle") {
      this.enableDrawing = true;
      this.clearDrawing();
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.CIRCLE
      );
      this.share.toolbox_obj.setActive = mode;
    }
  }
  clearDrawing() {
    if (!this.circle && !this.poly) {
      if (this.drawingManager) this.drawingManager.setMap(this.map.map);
    }
    if (this.poly) {
      this.poly.setMap(null);
    }
    if (this.circle) {
      this.circle.setMap(null);
    }
  }
  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(",");

      let temp = [];
      latlon.forEach((el) => {
        let s = el.split(" ");
        temp.push({ lat: +s[1], lng: +s[0] });
      });
      return temp;
    }
  }
  //POLYGON DRAWING ENDS HERE
  drawPolygon() {
    this.clearDrawing();
    if (this.userShape) {
      this.userShape.setMap(null);
    }
    // this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  renderCircle(map, center) {
    if (this.share.toolbox_obj.setActive == "circle") {
      //options to calculate geojson
      let options = {
        radius: 2,
        sides: 32,
      };
      this.userShape = new google.maps.Circle({
        center: center,
        map: map.map,
        radius: options.radius,
        fillColor: "#FF6600",
        fillOpacity: 0.3,
        strokeColor: "#FFF",
        strokeWeight: 1,
        draggable: true,
      });
      let coordinateArr = this.getGeoJson(center, options);
      this.polygonCords = coordinateArr.points;
      this.geomNew = "POLYGON((" + this.polygonCords + "))";
      this._zone.run(() => {
        if (this.share.toolbox_obj.toggle) {
          this.share.toolbox_obj.openDialog();
        }
      });
    }
  }

  getGeoJson(center, { radius, sides }) {
    var points = [],
      degreeStep = 360 / sides,
      latlngs = [];
    //convert circle to polygon function
    for (var i = 0; i < sides; i++) {
      var gpos = google.maps.geometry.spherical.computeOffset(
        center,
        radius,
        degreeStep * i
      );
      latlngs.push({ lat: gpos.lat(), lng: gpos.lng() });
      points.push([gpos.lng() + " " + gpos.lat()]);
    }
    // Duplicate the last point to close the geojson ring
    latlngs.push(latlngs[0]);
    points.push(points[0]);
    return {
      points: [points],
      latlngs: [latlngs],
    };
  }

  renderMap() {
    var options = {
      center: this.selected_property.lat
        ? new LatLon(this.selected_property.lat, this.selected_property.lon)
        : new LatLon(29.374732, 47.974151, 0),
      zoom: 18,
      showZoomControl: false,
      showScaleControl: false,
      showBaseLayer: false,
      showBaseMapTypeControl: false,
      showFullScreenControl: true,
      api: "googlemap",
      element: this.mapElement.nativeElement,
      source: {
        buildingId: this.share.block0_obj.selected_property.id,
        floorId: this.selected_floor.id,
        apiKey: "test",
        http: this.http,
        cityCode: this.api.city_code,
        assignment_name: this.assignment_detail.assignment_name ,
        property_id: this.share.block0_obj.selected_property.id,
        floor_id: this.selected_floor.id
      },
      styleMap: {
        floors: new Style({
          fillColor: "transparent",
          outlineColor: "transparent",
          baseHeight: 0,
          topHeight: 0.75,
          closeTop: true,
          clickable: false,
          zIndex: 10,
        }),
        units: new Style({
          fillColor: "#fbe0bb",
          outlineColor: "#64706c",
          outlineWidth: 1.0,
          fillOpacity: 1.0,
          baseHeight: 0.8,
          topHeight: 2.5,
          closeTop: true,
          iconWidth: 10.0,
          iconHeight: 10.0,
          clickable: true,
          zIndex: 11,
        }),
      },
    };

    //DRAWING MODULE STARTS HERE
    // let circle;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      circleOptions: {
        strokeColor: "#F1AE65",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#F1AE65",
        fillOpacity: 0.35,
        editable: false,
        draggable: false,
      },
      polygonOptions: {
        editable: false,
        draggable: false,
      },
    });

      //this.drawingManager.setMap(map);

      google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
        let radius = circle.getRadius();
        let clat = circle.getCenter().lat();
        let clng = circle.getCenter().lng();
        let position = new google.maps.LatLng(clat, clng);
        // this.map.setCenter(position);
        // this.map.fitBounds(circle.getBounds());
        this.circle = circle;
        const numberOfEdges = 32;

        let polygonc = this.generateGeoJSONCircle(position, radius, numberOfEdges);
        this.geomNew = polygonc.coordinates;
        this._zone.run(() => {
          if (this.share.toolbox_obj.toggle) {
            this.share.toolbox_obj.openDialog();

          }
        });
      });


    google.maps.event.addListener(
      this.drawingManager,
      "polygoncomplete",
      (polygon) => {
        let polygonBounds = polygon.getPath();
        let pBounds = polygon.getPaths();
        this.poly = polygon;
        let coordinates = [];
        for (let i = 0; i < polygonBounds.length; i++) {
          coordinates.push([
            polygonBounds.getAt(i).lng() + " " + polygonBounds.getAt(i).lat(),
          ]);
        }
        coordinates.push(coordinates[0]);
        this.polygonCords = [coordinates];
        this.geomNew = "POLYGON((" + this.polygonCords + "))";
        this._zone.run(() => {
          if (this.share.toolbox_obj.toggle) {
            this.share.toolbox_obj.openDialog();

          }
        });
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        this.drawingManager.setDrawingMode(null);
        if (event.type == "circle") {
          let radius = event.overlay.radius;
          let center = { lat: event.overlay.center.lat() };
         // this.share.toolbox_obj.openDialog();
        }
      }
    );


    //DRAWING MODULE ENDS HERE
    this.map = new IndoorMap(options, this.api, this.share);
    this.share.unit_obj.isFloorMap =true;
    // console.log(this.map);

    let udata = null;
    let that = this;
    this.map.addEventListener("click", (e, feature) => {
      //calling function to create cicle on click
      if(!feature?.attributes){
        return;
      }
      that.renderCircle(that.map, e.latLng);
      let unit_data = that.getUnitDetail(feature.attributes.unit_uid);
      if (!that.copied) {
        that.map.getUnitLayer().revertToDefaultStyle();
        that.setColorunitLayout("3");
      }
      if (feature != null) {

        let index, color;
        let unitUid = feature.getAttribute("unit_uid");
        that.selected_unit_uid = unitUid;
        if (!that.copied && unitUid) {
          let udata = that.getUnitDetail(unitUid);
          that.getUnitDetailApi(udata);
        }

        if (!unitUid) {
          that.notify.notify("Unit not found", "warn");
          return;
        }
        udata = that.getUnitDetail(unitUid);
        if (!udata) {
          that.notify.notify(
            "Unit ID " + unitUid + " not found in estater",
            "warn"
          );
          return;
        }
        if (that.copied && that.unit_Uids.indexOf(unitUid) > -1) {
          return;
        }
        if (that.copied) {
          if (udata.property_type_name != that.copied.property_type_name) {
            that.notify.notify(
              "Property type " +
                that.copied.property_type_name +
                " can not be paste on " +
                udata.property_type_name,
              "warn"
            );
            return;
          }
          index = that.unitGisUidsForPaste.indexOf(unitUid);
          color = "#ff9b29";
        } else {
          index = that.unit_Uids.indexOf(unitUid);
          color = "yellow";
        }
        if (index == -1) {
          that.map.getUnitLayer().setStyle(
            feature,
            new Style({
              fillColor: color,
              outlineColor: "#64706c",
              outlineWidth: 1.0,
              fillOpacity: 1.0,
              baseHeight: 0.8,
              topHeight: 2.5,
              closeTop: true,
              iconWidth: 10.0,
              iconHeight: 10.0,
            })
          );
          if (unitUid && !that.copied) {
            //that.unitGisUids.push(unitUid);
            that.unit_Uids[0] = unitUid; // select only one unit at a time
            that.tenantName.next(unitUid)
          } else {
            that.unitGisUidsForPaste.push(unitUid);
          }
        } else {
          let ucolor;
          if (unit_data != null && unit_data.status == 4) {
            ucolor = "#FB6D56";
          } else {
            ucolor = "#fbe0bb";
          }
          that.map.getUnitLayer().setStyle(
            feature,
            new Style({
              fillColor: ucolor,
              outlineColor: "#64706c", // dark gray
              outlineWidth: 1.0,
              fillOpacity: 1.0,
              baseHeight: 0.8,
              topHeight: 2.5,
              closeTop: true,
              iconWidth: 10.0,
              iconHeight: 10.0, //,
              //'iconUrl': 'https://s3.amazonaws.com/img-estater/kwt/deadmin/alocttstore.png'
            })
          );
          if (!that.copied) {
            that.unit_Uids.splice(index, 1);
          } else {
            that.unitGisUidsForPaste.splice(index, 1);
          }
        }

      } else {
        that.map.getUnitLayer().revertToDefaultStyle();
        that.setColorunitLayout("4");
      }
    });

  }


  //convert circle to polygon function
  generateGeoJSONCircle(center: any, radius: any, numSides: any) {

    var points = [],
      degreeStep = 360 / numSides;

    for (var i = 0; i < numSides; i++) {
      var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
      points.push([gpos.lng() + ' ' + gpos.lat()]);
    };

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    return {
      type: 'Polygon',
      coordinates:  "POLYGON((" + points + "))",
    };
  }

  setColorunitLayout(from?) {

    // console.log(from);
    let getUnitLayerData = this.map.getUnitLayer();
    if (getUnitLayerData) {

      // setTimeout(() => {
          let laydata = getUnitLayerData.dataForStyle;
          laydata = laydata?.features;
          laydata?.forEach((element) => {
            let color = "#FBE0BB"; // light yellow
            let unitdetail = this.getUnitDetail(element.properties.unit_uid);
            if (
              unitdetail !== null &&
              unitdetail?.is_edited &&
              unitdetail?.status == 1
            ) {
              color = "#C1F5AD"; //green
              //console.log(unitdetail);
            }

            if (unitdetail !== null && unitdetail?.status == 4) {
              //console.log(unitdetail);
              color = "#FB6D56"; //red
            }

            if (unitdetail !== null && unitdetail?.status == 6) {
              //console.log(unitdetail);
              color = "#FBD356"; // dark yellow
            }
            this.map.getUnitLayer().setStyle(
              { id: element.properties.unit_id },
              new Style({
                fillColor: color,
                outlineColor: "#64706c", //gray color
                outlineWidth: 1.0,
                fillOpacity: 1.0,
                baseHeight: 0.8,
                topHeight: 2.5,
                closeTop: true,
                iconWidth: 10.0,
                iconHeight: 10.0,
              })
            );
          });
      // }, 1500);

    }
  }

  getUnitDetailApi(unit) {
    if (unit) {
      this.load = false;
      let property_type_id = this.fnc.getArrayValue(
        "name",
        unit.property_type_name,
        this.share.unit_obj.property_type_list
      );
      this.share.block0_obj.property_type_id = property_type_id.id;
      this.map_loader=true;
      // this.cdr.detectChanges();
      this.share.unit_obj.getUnitAttributes(false);
      this.api
        .getEpsData(
          "deadmin/" +
            this.assignment_detail.assignment_name +
            "/properties/" +
            this.share.block0_obj.selected_property.id +
            "/floors/" +
            this.selected_floor.id +
            "/units/" +
            unit.id
        )
        .subscribe(
          {
            next: (data: any) => {
              data["id"] = data.unit_id;
              this.share.unit_obj.unit_detail = data;
              this.share.unit_obj.selected_unit = data;
              this.share.selected_obj_uid = data.uid;
              this.load = true;
              this.map_loader=false;
              // this.cdr.detectChanges();
            },
           error: (err) => {
              this.load = true;
              this.map_loader=false;
            }
          }
        );
    }
  }
}
